import React,{useState,useEffect} from 'react';
import './css/Dashboard.css';
import '../../css/Contribute.css'
import firebase from 'firebase/app'
import axios from 'axios'
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';



const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:'rgb(255, 255, 255)', 
  }));
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    paddingLeft: theme.spacing(4),
  }));
  
function DashboardComponent() {
    const currentUser  = firebase.auth().currentUser;
    const [showmore, setshowmore] = useState(false);
    const [Name, setName] = useState("")

    const [expanded, setExpanded] = useState('panel1');
    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
    // const location = useLocation();
    // console.log("Location : ",props.location.pathname)
    // console.log("Data",props.location.state)
    
    useEffect(() => {
        const data = JSON.stringify({
            Email : currentUser.email
          }) 
        axios.post("https://thxakskwod.execute-api.ap-south-1.amazonaws.com/default/myFirstFunction", data)
        .then(function (response) {
                if(response.data.length){
                    setName(response.data[0]['StudentName']) 
                }
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
        // console.log("response data : ",error.response.data);
        // console.log("response status :  ",error.response.status);
        // console.log("response headers : ",error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
        // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
        }
      // console.log(error.config);

      // console.log(error);
      });     
    }, [])
    const showMore = () => {
        if(showmore) 
            setshowmore(!showmore)
        else 
            setshowmore(!showmore)
    }

    return (
        <div className='dashboard'>
            <h1 className='dashbaordHeader'>Dashboard</h1>
            <h1>Hi,{' '}<span>{Name}</span></h1>

            <div className="dashboard_content">
                <div className="announcements">
                               <div className={`announcementsdiv ${showmore && "showmore"}`}>
                                    <span className="headline">Announcements</span>
                                    <text>
                                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                    <Typography  className="event_headline ">Apply for Internship roles</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography className="event_description"> 
                                                        We have added a Career page where you can apply for various internships in your domain!! <Link to="/careers">Apply for internships now!</Link>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                      
                                    </text>
                                    <hr className="hr"/>
                                    {/* <text>
                                        <a className="event_headline">Ongoing mentorship and guidance sessions for Second Year students</a>
                                        <br/>
                                    </text>
                                    <hr className="hr"/> */}
                                    <text>
                                        {/* <a className="event_headline"><Link to="/profile">Check your membership renewal date</Link></a> */}
                                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                    <Typography  className="event_headline">Check your membership renewal date</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography className="event_description"> 
                                                           You will be able renew your membership if your validity has been over.Refer your joining date.                                                </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                    </text>
                                    <hr className="hr"/>
                                    {/* <text>
                                        <a className="event_headline"> <Link to="/contribute">Contact for contribution in study resources, hosting events and project ideas</Link>
                                        </a>
                                        <br/>
                                    </text>
                                    <hr className="hr"/> */}
                                    <text>
                                        {/* <a className="event_headline"><Link to="/events">Checkout our previous event</Link></a> */}
                                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                    <Typography  className="event_headline">Checkout our previous event</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography className="event_description"> 
                                                    Checkout our past event "On Device Machine Learning using Tensorflow Lite". 
                                                    <br/>
                                                    Session recording: <a href="https://drive.google.com/file/d/15JNbcT2avoG7SmybpYzETfNFQhVBo3Ji/view?usp=sharing" target='_blank'>https://drive.google.com/file/d/15JNbcT2avoG7SmybpYzETfNFQhVBo3Ji/view?usp=sharing</a>                                            
                                                    </Typography>
                                            </AccordionDetails>
                                            </Accordion>
                                    </text>
                                    <hr className="hr"/>
                                    <text>
                                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                    <Typography  className="event_headline">Happy Republic Day</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography className="event_description"> 
                                                    Let's salute the sacrifices made by freedom fighters and soldiers who made us realize the dream of an independent and unified India. Happy Republic Day!
                                                    <br/><br/>
                                                    <p>Instagram: <a href='https://www.instagram.com/p/CZLfcpiNqBR/?utm_medium=copy_link' target='="_blank'>https://www.instagram.com/p/CZLfcpiNqBR/?utm_medium=copy_link</a></p>
                                                    <p>Linkdin: <a href="https://www.linkedin.com/posts/csi-phcet_happyrepublicday-csiphcet-activity-6891958791093465088-7c9V" target='_blank'>https://www.linkedin.com/posts/csi-phcet_happyrepublicday-csiphcet-activity-6891958791093465088-7c9V</a></p>
                                                    <p>Twitter: <a href="https://twitter.com/csiphcet/status/1486194366956072963?t=aoPrsRqub5GXk6KqCmzznA&s=19" target='_blank'>https://twitter.com/csiphcet/status/1486194366956072963?t=aoPrsRqub5GXk6KqCmzznA&s=19</a></p>
                                                    <p>Facebook: <a href="https://www.facebook.com/103845324878108/posts/386277106634927/" target='_blank'>https://www.facebook.com/103845324878108/posts/386277106634927/</a></p>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                    </text>
                                    {/* <text>
                                        <a className="event_headline" href="https://www.instagram.com/tv/CX5V-gHJ6I2/?utm_medium=copy_link">Merry Christmas everyone!! </a>
                                        <br/>
                                    </text> */}
                                    
                                   
                                </div>
                
                                    {/* <small className="see_more" onClick={showMore}>
                                        Show
                                        {
                                            (showmore)?
                                                " Less":" More"
                                        }
                                    </small> */}
                </div>
                <div className="up_next">
                                <div>
                                    <span className="headline">Up Next</span>
                                    <h3 style={{margin:"1.5rem"}}>Coming Soon</h3>                                    {/* <EventsCard  className="evenetcard" image={'https://csi.phcet.ac.in/static/media/reactjs.32e1a3f7.jpeg'}/> */}
                                </div>
                                {/* <small className="see_more">See More</small> */}
                </div>        
            </div> 
        </div>
    )
}

export default DashboardComponent;
