import React, { useEffect } from 'react';
import '../css/Events.css';
import EventCard from './EventCard';
import { Link } from 'react-router-dom';

//Event Posters
import Pcassemble from '../assets/pages/events-Poster/pcassembly.jpg'
import Iot from '../assets/pages/events-Poster/iot.jpg'
import Iot2 from '../assets/pages/events-Poster/iot2.jpg'
import Iot3 from '../assets/pages/events-Poster/iot3.jpg'
import FunFriday from '../assets/pages/events-Poster/funfriday.jpg'
import Mlusingtf from '../assets/pages/events-Poster/MlUsingTf.jpg'
import ChillWithCsi from '../assets/pages/events-Poster/ChillWithCsi.jpg'
import ReactJS from '../assets/pages/events-Poster/reactjs.jpg'
import Github from '../assets/pages/events-Poster/git-github.jpg'
import MockApti2 from '../assets/pages/events-Poster/mockapti2.jpg'
import MockApti1 from '../assets/pages/events-Poster/mockapti1.jpg'
import Codecrush from '../assets/pages/events-Poster/codecrush.jpg'
import Java from '../assets/pages/events-Poster/java.jpg'
import PhotoShop from '../assets/pages/events-Poster/photoshop.jpg'
import CompetitiveProgramming from '../assets/pages/events-Poster/competitiveprogramming.jpg'
import Python from '../assets/pages/events-Poster/python.jpg'
import EthicalHacking from '../assets/pages/events-Poster/ethicalhacking.jpg'
import CsiUi from '../assets/pages/events-Poster/csiui.jpg'
import Graphicon from '../assets/pages/events-Poster/graphicon.png'
import GitFusion from '../assets/pages/events-Poster/gitfusion.png'
import EthicalQuest from '../assets/pages/events-Poster/ethicalquest.jpg'
import Mjunction from '../assets/pages/events-Poster/mentorshipjunction.jpg';
import Thinkai from '../assets/pages/events-Poster/thinkai.jpg';
import Insight from '../assets/pages/events-Poster/internInsight.jpg';

import Header from '../components/common/Header/Header';
import Footer from './Footer';

function Events() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    }, []);

    return (
        <>
            <Header />

            <div className="events">
                {/* <h2>Upcoming Events</h2>
                <div className="eventcards wrapper">
                </div>
                
                <span style={{marginTop: '1.5rem'}}>Coming Soon</span>
                <div className="eventcards wrapper">
                    <Link to="/events/ethicalquest"><EventCard image={EthicalQuest} /></Link>
                </div>
                <br /> */}
                <span>Past Events</span>
                {/* <p>There are no past events at this time.</p> */}
                <div className="eventcards wrapper">
                    <Link to="/events/mjunction"><EventCard image={Mjunction} /></Link>
                    <Link to="/events/thinkai"><EventCard image={Thinkai} /></Link>
                    <Link to="/events/insight"><EventCard image={Insight} /></Link>
                    <Link to="/events/ethicalquest"><EventCard image={EthicalQuest} /></Link>
                    <Link to="/events/gitfusion"><EventCard image={GitFusion} /></Link>
                    <Link to="/events/graphicon"><EventCard image={Graphicon} /></Link>
                    <Link to="/events/csiUi"><EventCard image={CsiUi} /></Link>
                    <Link to="/events/iot3"><EventCard image={Iot3} /></Link>
                    <Link to="/events/iot2"><EventCard image={Iot2} /></Link>
                    <Link to="/events/iot"><EventCard image={Iot} /></Link>
                    <Link to="/events/pc-assembly"><EventCard image={Pcassemble} /></Link>
                    <Link to="/events/fun-friday"><EventCard image={FunFriday} /></Link>
                    <Link to="/events/ml-using-tf"><EventCard image={Mlusingtf} /></Link>
                    <Link to="/events/chill-with-csi"><EventCard image={ChillWithCsi} /></Link>
                    <Link to="/events/web-development"><EventCard image={ReactJS} /></Link>
                    <Link to="/events/github"><EventCard image={Github} /></Link>
                    <Link to="/events/mockaptitude2"><EventCard image={MockApti2} /></Link>
                    <Link to="/events/mockaptitude1"><EventCard image={MockApti1} /></Link>
                    <Link to="/events/codecrush"><EventCard image={Codecrush} /></Link>
                    <Link to="/events/java"><EventCard image={Java} /></Link>
                    <Link to="/events/photoshop"><EventCard image={PhotoShop} /></Link>
                    <Link to="/events/compitetive-programming"><EventCard image={CompetitiveProgramming} /></Link>
                    <Link to="/events/python"><EventCard image={Python} /></Link>
                    <Link to="/events/ethical-hacking"><EventCard image={EthicalHacking} /></Link>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Events;
