import { Avatar, Button} from '@material-ui/core';
import { AccessTime, LocationOnSharp} from '@material-ui/icons';
import React,{useEffect } from 'react';
import './css/EventDetail.css';
import logo from './assets/logo.png';
import {FacebookIcon,WhatsappIcon,TwitterIcon,FacebookShareButton,WhatsappShareButton,TwitterShareButton} from 'react-share';
import Header from './components/common/Header/Header';
import Footer from './pages/Footer';
import { Carousel } from 'react-bootstrap';


function EventDetail1({eachEvent}) {
    useEffect(() => {
            window.scrollTo({
              top: 0, 
            });
          }, []);
        let currentURL = '\n'+window.location.href;

        // const months = {"January":"1", "February":"2", "March":"3","April":"4", "May":"5", "June":"6", "July":"7", "August":"8", "September":"9", "October":"10", "November":"11", "December":"12"};
        // let current_datetime = new Date();
        // let current_date = current_datetime.getDate() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getFullYear()
        // console.log("currentDate :",current_date)
        // var date = eachEvent.EventStartDate.split(" ")
        // date[0] = date[0].slice(0,date[0].length-2)
        // date[1] = months[date[1]]
        // var eventdate = date.join("-")
        // console.log(eventdate<current_date)
    return (
        <>
        <Header />
            <div className="eventdetail">
                    <div className="eventdetail_card">
                            <img
                            src={eachEvent.EventPoster}
                            alt="event card"
                            />
                    </div>
                    <div className="eventdetail_details">
                        <h2>{eachEvent.EventTitle}</h2>
                        <div className="eventdetail_details_info organizer">
                            <Avatar src={logo} alt="logo"/>
                            <div className="eventdetail_details_info_text">
                                    <span>Presented by</span>
                                    <br/>
                                    <span>{eachEvent.EventOrganizer}</span>
                            </div>
                        </div>
                        <div className="eventdetail_details_info">
                            <AccessTime fontSize="large"/>
                            <div className="eventdetail_details_info_text">
                                <span>{eachEvent.EventDate}</span>
                                <br/>
                                <span>{eachEvent.EventTime}</span>
                            </div>
                        </div>
                        <div className="eventdetail_details_info">
                            <LocationOnSharp fontSize="large"/>
                            <div className="eventdetail_details_info_text">
                                <span>{eachEvent.EventLocation}</span>
                            </div>
                        </div>
                        <div className="button_style">
                        { 
                                (eachEvent.upcomingEvent)?
                                        <Button 
                                                href={eachEvent.EventRegisterationLink} 
                                                target="blank" 
                                                className="apply_button"
                                                shape="round"
                                                variant="inherit" color="Success"
                                                fullWidth>
                                            REGISTER
                                        </Button>:
                                        <></>
                        }
                        </div>
                        <h3>Share This Event On</h3>
                        <div className="share_buttons">
                            <FacebookShareButton 
                                    quote = {`Join me at ${eachEvent.EventTitle},  an event presented by ${eachEvent.EventOrganizer} and hosted by the CSI PHCET Students Club! Register here: `}
                                    url={currentURL}
                                    // hashtag="#csiphcet"
                                    >
                                        <FacebookIcon round={true}/>
                            </FacebookShareButton>
                            <WhatsappShareButton 
                                    title={`Join me at ${eachEvent.EventTitle},  an event presented by ${eachEvent.EventOrganizer} and hosted by the CSI PHCET Students Club! Register here: `}
                                    url={currentURL}
                                    >
                                        <WhatsappIcon round={true}/>
                            </WhatsappShareButton>
                            <TwitterShareButton
                                    title={`Join me at ${eachEvent.EventTitle},  an event presented by ${eachEvent.EventOrganizer} and hosted by the CSI PHCET Students Club! Register here: `}
                                    url={currentURL}
                                    // hashtags="#csiphcet"
                                    >
                                    <TwitterIcon round={true}/>
                            </TwitterShareButton>
                        </div>
                    </div>
        </div>  
        <div className="Description">
                {eachEvent.EventDetails}
                {
                    (eachEvent.EventPhotos.length)?
                    (
                        <div style={{display:'flex',justifyContent:'center'}}>
                            <Carousel>
                                {
                                    (eachEvent.EventPhotos).map( (eachPhoto) => (
                                        <Carousel.Item >
                                            <img className="carousel"
                                                src={eachPhoto} alt="slide" />
                                        </Carousel.Item>
                                    ))
                                }
                            
                            </Carousel>
                         </div>
                    ):<></>
                }
                
        </div>

        <Footer />
        </>
    )
}

export default EventDetail1;
