import React from 'react';

const Mjunction = () => {
  return (
    <>
      <p>✨𝗚𝗿𝗲𝗲𝘁𝗶𝗻𝗴𝘀✨</p>
      <p>𝗥𝗲𝗮𝗱𝘆 to 𝘀𝘂𝗽𝗲𝗿𝗰𝗵𝗮𝗿𝗴𝗲 your journey to 𝘀𝘂𝗰𝗰𝗲𝘀𝘀?🧐 Don't miss our  𝗢𝗻𝗹𝗶𝗻𝗲 𝗺𝗲𝗻𝘁𝗼𝗿𝘀𝗵𝗶𝗽 𝘀𝗲𝘀𝘀𝗶𝗼𝗻! </p>

      <p>💥𝗠𝗘𝗡𝗧𝗢𝗥𝗦𝗛𝗜𝗣 JUNCTION : Student 𝗚𝘂𝗶𝗱𝗮𝗻𝗰𝗲 𝘀𝗲𝗿𝗶𝗲𝘀💥</p>

      <p>
      Connect with mentors who are eager to share their knowledge and experience to empower you on your journey to success.🤝🚀
      </p>

      <p>🗣𝗦𝗽𝗲𝗮𝗸𝗲𝗿: Mr.Sami Ahmed &  Mr.Sahil Gaikwad</p>

      <p>📌𝗘𝘅𝗰𝗹𝘂𝘀𝗶𝘃𝗲 𝗼𝗻𝗹𝘆 𝗳𝗼𝗿 𝗖𝗦𝗜 𝗠𝗲𝗺𝗯𝗲𝗿𝘀.</p>

      <p>
      ▫️𝗧𝗼𝗽𝗶𝗰𝘀 𝘁𝗼 𝗯𝗲 𝗰𝗼𝘃𝗲𝗿𝗲𝗱 𝗱𝘂𝗿𝗶𝗻𝗴 𝘀𝗲𝘀𝘀𝗶𝗼𝗻:
        <ul>
          <li>🚀𝘗𝘭𝘢𝘤𝘦𝘮𝘦𝘯𝘵 𝘎𝘶𝘪𝘥𝘢𝘯𝘤𝘦</li>
          <li>🧠𝘈𝘤𝘢𝘥𝘦𝘮𝘪𝘤 𝘦𝘹𝘤𝘦𝘭𝘭𝘦𝘯𝘤𝘦</li>
          <li>🌱 𝘗𝘦𝘳𝘴𝘰𝘯𝘢𝘭 𝘨𝘳𝘰𝘸𝘵𝘩</li>
          <li>🎯𝘎𝘰𝘢𝘭 𝘈𝘤𝘩𝘪𝘦𝘷𝘦𝘮𝘦𝘯𝘵</li>
        </ul>
      </p>

      <p>
        📅 𝗗𝗮𝘁𝗲: 24th March, 2024 <br />
        🕑 𝗧𝗶𝗺𝗲: 11:00 am <br />
        📍 𝗩𝗲𝗻𝘂𝗲: Google Meet <br />
      </p>

      <p>
        🎯𝗙𝗼𝗿 𝗮𝗻𝘆 𝗳𝘂𝗿𝘁𝗵𝗲𝗿 𝗾𝘂𝗲𝗿𝗶𝗲𝘀, 𝗱𝗼 𝗻𝗼𝘁 𝗵𝗲𝘀𝗶𝘁𝗮𝘁𝗲 𝘁𝗼 𝗿𝗲𝗮𝗰𝗵 𝗼𝘂𝘁 𝘁𝗼 𝗼𝘂𝗿 𝗰𝗼𝗼𝗿𝗱𝗶𝗻𝗮𝘁𝗲𝘀:
        <br />
        📞Tanisha Paras: +91 8591121224<br />
        📞Tanay Jadhav : +91 8879013023 <br />
      </p>

      <p>𝘚𝘦𝘪𝘻𝘦 𝘵𝘩𝘪𝘴 𝘰𝘱𝘱𝘰𝘳𝘵𝘶𝘯𝘪𝘵𝘺 𝘵𝘰 𝘤𝘰𝘯𝘯𝘦𝘤𝘵 𝘸𝘪𝘵𝘩 𝘮𝘦𝘯𝘵𝘰𝘳𝘴 𝘥𝘦𝘥𝘪𝘤𝘢𝘵𝘦𝘥 𝘵𝘰 𝙔𝙊𝙐𝙍 𝘴𝘶𝘤𝘤𝘦𝘴𝘴✨</p>

      <p>
        Warm regards,
        <br />
        𝗧𝗲𝗮𝗺 𝗖𝗦𝗜 𝗣𝗛𝗖𝗘𝗧
      </p>
    </>
  );
};

export default Mjunction;
