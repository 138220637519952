import React from 'react'
import CardView from '../components/about/Cards/CardTeam'
import '../css/Team.css'

/* Chairman Imports */
import chairman from '../assets/pages/about/team-members/chairman.jpg'
import jointchairman from '../assets/pages/about/team-members/joint-chairman.jpg'


/* Secretary Imports */ 
import headsecretary from '../assets/pages/about/team-members/head-secretary.jpg'
// import jointsecretary from '../assets/pages/about/team-members/joint-secretary.jpg'
import jointsecretary1 from '../assets/pages/about/team-members/TanishaP.jpg'
import mentorHead from '../assets/pages/about/team-members/mentor.jpg'


/* Treasurer Imports */ 
import headtreasurer from '../assets/pages/about/team-members/treasurer.jpg'
import jointtreasurer from '../assets/pages/about/team-members/joint-treasurer.jpg'


/* Event Team Imports */
// import eventhead from '../assets/pages/about/team-members/event-head.jpg'
import eventteam1 from '../assets/pages/about/team-members/jt-event-head.jpg'
// import eventteam2 from '../assets/pages/about/team-members/event-team-member-2.jpg'
import eventteam3 from '../assets/pages/about/team-members/event-team-3.jpg'
import eventteam4 from '../assets/pages/about/team-members/ParthB.jpg'
import eventteam5 from '../assets/pages/about/team-members/ShreyanshiS.jpg'
import eventteam6 from '../assets/pages/about/team-members/AditiG.jpg'


/* Publicity Team Imports */
// import publicityteam1 from '../assets/pages/about/team-members/publicity-team-member-1.jpg'
import publicityteam2 from '../assets/pages/about/team-members/publicity-team-2.jpg'
import publicityteam3 from '../assets/pages/about/team-members/publicity-team-member-3.jpg'
// import publicityteam4 from '../assets/pages/about/team-members/publicity-team-member-4.jpg'
import publicityteam5 from '../assets/pages/about/team-members/publicity-team-member-5.jpg'
// import publicityteam6 from '../assets/pages/about/team-members/publicity-team-member-6.jpg'
import publicityteam7 from '../assets/pages/about/team-members/SakshamT.jpg'
import publicityteam8 from '../assets/pages/about/team-members/PoojaV.jpg'


/* Social Media Team Imports */
import socialmediamanager from '../assets/pages/about/team-members/social-media-manager.jpg'
import socialmediateam1 from '../assets/pages/about/team-members/social-media-1.jpg'


/* Technical Team Imports */
import technicalhead from '../assets/pages/about/team-members/technical-head.jpg'
// import technicalteam1 from '../assets/pages/about/team-members/technical-team-member-1.jpg'
import technicalteam2 from '../assets/pages/about/team-members/technical-team-member-2.jpg'
import technicalteam3 from '../assets/pages/about/team-members/SourabhG.jpg'


/* Business Team Imports */ 
// import bdhead from '../assets/pages/about/team-members/bd-head.jpg'
// import jtbdhead from '../assets/pages/about/team-members/joint-bd-head.jpg'
// import bdexec1 from '../assets/pages/about/team-members/bd-exec-1.jpg'
// import bdexec2 from '../assets/pages/about/team-members/bd-exec-2.jpg'
import bdexec3 from '../assets/pages/about/team-members/bd-exec-3.jpg'
// import bdexec4 from '../assets/pages/about/team-members/bd-exec-4.jpg'
import bdexec5 from '../assets/pages/about/team-members/bd-exec-5.jpg'
import bdexec6 from '../assets/pages/about/team-members/JanhviJ.jpg'
import bdexec7 from '../assets/pages/about/team-members/ManishM.jpg'
import bdexec8 from '../assets/pages/about/team-members/AdityaP.jpg'


/* Editor Team Imports */
// import editorhead from '../assets/pages/about/team-members/editor-head.jpg'
import editor1 from '../assets/pages/about/team-members/editor-1.jpg'
import editor2 from '../assets/pages/about/team-members/editor-2.jpg'
import editor3 from '../assets/pages/about/team-members/RohanG.jpg'
import editor4 from '../assets/pages/about/team-members/DishaN.jpg'


/* Developer Team Imports */
// import devhead from '../assets/pages/about/team-members/developer-head.jpg'
// import appdev from '../assets/pages/about/team-members/android-developer.jpg'
// import webdev from '../assets/pages/about/team-members/web-developer.jpg'
import jtwebdev from '../assets/pages/about/team-members/joint-web-developer.jpg'
import devteam1 from '../assets/pages/about/team-members/VinyasH.jpg'
import devteam2 from '../assets/pages/about/team-members/SwapnilC.jpg'


function Team() {
    return (
        <div className="team">
            <br />
            {/* Peeps with Chairs XD*/}
            <div>
                <div className='two-membered'>
                <CardView socialtwitter={'https://twitter.com/sub0dhhh'} sociallinkdin={'https://www.linkedin.com/in/subodh-kadam-88a010220'} profileimage={chairman} profilename={"Subodh Kadam"} profilerole={"Chairman"} />
                {/* <CardView socialtwitter={'https://twitter.com/SanskritiKhars1'} sociallinkdin={'https://www.linkedin.com/in/sanskriti-kharsamble-037935182/'} profileimage={chairman} profilename={"Sanskriti K."} profilerole={"Chairman"} /> */}
                {/* <CardView socialtwitter={''} sociallinkdin={''} profileimage={jointchairman} profilename={"Prathmesh P."} profilerole={"Jt. Chairman"} /> */}
                <CardView socialtwitter={'https://mobile.twitter.com/Rajat__Rai'} sociallinkdin={'https://www.linkedin.com/in/rajat-rai-96298222b'} profileimage={jointchairman} profilename={"Rajat Rai"} profilerole={"Jt. Chairman"} />
                </div>
            </div>
            <hr />

            {/* Secretary's Tomb */}
            <div>
                {/* <CardView socialtwitter={'https://twitter.com/ShettyPaavana'} sociallinkdin={'https://www.linkedin.com/in/paavana-shetty-8a5b7a20b/'} profileimage={headsecretary} profilename={"Paavana Shetty"} profilerole={"Secretary"} /> */}
                <CardView socialtwitter={'https://twitter.com/HrishiBhilare'} sociallinkdin={'www.linkedin.com/in/hrishi-bhilare-8b5140252'} profileimage={headsecretary} profilename={"Rushikesh Bhilare"} profilerole={"Secretary"} />
                <CardView socialtwitter={'https://twitter.com/tanisha__paras'} sociallinkdin={'https://www.linkedin.com/in/tanisha-paras-995070214/'} profileimage={jointsecretary1} profilename={'Tanisha Paras'} profilerole={'Jt. Secretary'} />
                <CardView socialtwitter={'https://twitter.com/yashsmahajan82'} sociallinkdin={'https://www.linkedin.com/in/yash-mahajan-90b0b5219'} profileimage={mentorHead} profilename={"Yash Mahajan"} profilerole={"Mentorship Head"} />
            </div>
            <hr />

            {/* Treasurers */}
            <div>
                <div className='two-membered'>
                <CardView socialtwitter={'https://twitter.com/Sahil1523?t=_AVkr3P5SDdXI52UVVTe3g&s=08'} sociallinkdin={'https://www.linkedin.com/in/sahil-gaikwad-b3a980223'} profileimage={headtreasurer} profilename={"Sahil Gaikwad"} profilerole={"Treasurer"} />
                <CardView socialtwitter={'https://twitter.com/jtanay1009'} sociallinkdin={'https://www.linkedin.com/in/tanay-jadhav-1a4a89252/'} profileimage={jointtreasurer} profilename={"Tanay Jadhav"} profilerole={"Jt. Treasurer"} />
                </div> 
            </div>
            <hr />

            {/* Event Team */}
            <div>
                <div className='two-membered'>
                {/* <CardView socialtwitter={'https://twitter.com/Sakshi__25'} sociallinkdin={'https://www.linkedin.com/in/sakshi-chauhan-6b6a651a9/'} profileimage={eventhead} profilename={"Sakshi Chauhan"} profilerole={"Event Head"} /> */}
                <CardView socialtwitter={'https://twitter.com/beg_ramsha?t=1R1iiwwVpapgiwU8_5Px4w&s=08'} sociallinkdin={'https://www.linkedin.com/in/ramsha-beg-2002/'} profileimage={eventteam3} profilename={"Ramsha Beg"} profilerole={"Event Head"} />
                <CardView socialtwitter={'https://twitter.com/NiharPasi?t=9nvFv5QldjWG_jdnxMT6YA&s=08'} sociallinkdin={'https://www.linkedin.com/in/nihar-pasi-b28443232'} profileimage={eventteam1} profilename={"Nihar Pasi"} profilerole={"Jt. Event Head"} />
                {/* <CardView socialtwitter={'https://twitter.com/BhaktiBhoir0404?t=C0R_3XfJrn55UUPOcO6iSg&s=08'} sociallinkdin={'https://www.linkedin.com/in/bhakti-bhoir-786054232'} profileimage={eventteam2} profilename={"Bhakti Bhoir"} profilerole={"Event Team Member"} /> */}
                </div> 
            </div>
            <div>
                <CardView socialtwitter={'https://twitter.com/bhoir_part58754?t=_d8pw4FF1mUIsSgYtKK95Q&s=09'} sociallinkdin={'https://www.linkedin.com/in/parth-bhoir-781727289'} profileimage={eventteam4} profilename={'Parth Bhoir'} profilerole={'Event Team Member'} />
                <CardView socialtwitter={'https://twitter.com/_shreyanshi_19_?t=TSD1MUJpy4ByDidp2ExQIA&s=09'} sociallinkdin={'https://www.linkedin.com/in/shreyanshi-srivastava-5b2361259'} profileimage={eventteam5} profilename={'Shreyanshi Srivastava'} profilerole={'Event Team Member'} />
                <CardView socialtwitter={'twitter.com/AditiGavade29'} sociallinkdin={'https://www.linkedin.com/in/aditigavade/ '} profileimage={eventteam6} profilename={'Aditi Gavade '} profilerole={'Event Team Member'} />    
            </div>   
            <hr />

            {/* Publicity Team */}
            <div>
                <div className='two-membered'> 
                {/* <CardView socialtwitter={''} sociallinkdin={''} profileimage={publicityteam1} profilename={"Rutuja Kore"} profilerole={"Publicity Team Member"} /> */}
                <CardView socialtwitter={''} sociallinkdin={''} profileimage={publicityteam3} profilename={"Tanvi Patil"} profilerole={"Publicity Head"} />
                <CardView socialtwitter={'https://twitter.com/YashShi78753687?t=_F0weH0kU-sQlu2KPIAC4A&s=08'} sociallinkdin={'https://www.linkedin.com/in/yash-shinde-079156232'} profileimage={publicityteam2} 
                profilename={"Yash Shinde"} profilerole={"Publicity Team Member"} />
                </div>
            </div>
            <div>
                <CardView socialtwitter={'https://twitter.com/ShagunJha2?t=bpfxZqIxHVLDM5E8UwhHjg&s=09'} sociallinkdin={'https://www.linkedin.com/in/shagun-jha-6279a4252'} profileimage={publicityteam5} profilename={"Shagun Jha"} profilerole={"Publicity Team Member"} />
                <CardView socialtwitter={'https://twitter.com/Friend_notfoe?t=qT-DBKVs874Be_fUSXLiKg&s=08'} sociallinkdin={'https://www.linkedin.com/in/saksham-tiwari-0b5980289'} profileimage={publicityteam7} profilename={'Saksham Tiwari'} profilerole={'Publicity Team Member'} />
                <CardView socialtwitter={'https://twitter.com/mer_meii23'} sociallinkdin={'https://www.linkedin.com/in/pooja-vishwakarma-b91a89281'} profileimage={publicityteam8} profilename={'Pooja Vishwakarma'} profilerole={'Publicity Team Member'} />
            </div>
            <hr />

            {/* Social Media Team */}
            <div>
                <div className="two-membered">
                <CardView socialtwitter={'https://twitter.com/PREM30116'} sociallinkdin={'https://www.linkedin.com/in/prem-singh-07a46322b'} profileimage={socialmediamanager} profilename={"Prem Singh"} profilerole={"Social Media Head"} />
                <CardView socialtwitter={''} sociallinkdin={''} profileimage={socialmediateam1} profilename={"Pratish Patil"} profilerole={"Social Media Team Member"} />
                </div>
            </div>
            <hr />

            {/* Technical Team */}
            <div>
                {/* <CardView socialtwitter={''} sociallinkdin={''} profileimage={technicalhead} profilename={"Abhishek Raha"} profilerole={"Technical Head"} /> */}
                <CardView socialtwitter={'https://twitter.com/Pgoverkar'} sociallinkdin={'https://www.linkedin.com/in/pratham-goverkar-09256622b/'} profileimage={technicalhead} profilename={"Pratham Goverkar"} profilerole={"Technical Head"} />
                <CardView socialtwitter={'https://twitter.com/AbhishekAmbre_?t=8OH85_owlMtNQK9NNpQQHQ&s=09'} sociallinkdin={'https://www.linkedin.com/in/abhishek-ambre-53905b253'} profileimage={technicalteam2} profilename={"Abhishek Ambre"} profilerole={"Technical Team Member"} />
                <CardView socialtwitter={'https://twitter.com/SourabhX9'} sociallinkdin={'https://www.linkedin.com/in/sourabh-gokave-99b3aa238'} profileimage={technicalteam3} profilename={'Sourabh Gokave'} profilerole={'Technical Team Member'} />
            </div>
            <hr />

            {/* Business Development Team */}
            <div>
                {/* <CardView socialtwitter={'https://twitter.com/Devang79379124?s=08'} sociallinkdin={'https://www.linkedin.com/in/devang-parate-b3602a218/'} profileimage={bdhead} profilename={"Devang Parate"} profilerole={"Business Development Head"} /> */}
                {/* <CardView socialtwitter={'https://twitter.com/shrupatil81?s=08'} sociallinkdin={'https://www.linkedin.com/in/shrushti-patil-88544b1b6'} profileimage={jtbdhead} profilename={"Shrushti Patil"} profilerole={"Joint Business Development Head"} /> */}
                {/* <CardView socialtwitter={''} sociallinkdin={''} profileimage={bdexec1} profilename={"Salil Liman"} profilerole={"Business Development Executive"} /> */}
                {/* <CardView socialtwitter={''} sociallinkdin={'https://linkedin.com/in/prajwalmali'} profileimage={bdexec2} profilename={"Prajwal Mali"} profilerole={"Business Development Executive"} /> */}
            </div>
            <div>
                <div className='two-membered'>
                <CardView socialtwitter={'https://twitter.com/uknowRishabh?t=AzSCwLvialvoFk1zYfemAQ&s=09'} sociallinkdin={'https://www.linkedin.com/in/rishabh-kumar-a018901ba'} profileimage={bdexec3} profilename={"Rishabh Kumar"} profilerole={"Business Development Head"} />
                {/* <CardView socialtwitter={'https://twitter.com/utkarshaC17'} sociallinkdin={'https://www.linkedin.com/in/utkarsha-chaudhari-723943232/'} profileimage={bdexec4} profilename={"Utkarsha Chaudhari"} profilerole={"Business Development Executive"} /> */}
                <CardView socialtwitter={'https://twitter.com/NandiniBodkhe?t=xpTE9FTk8uKC_oFW3iM0vg&s=09'} sociallinkdin={'https://www.linkedin.com/in/nandini-bodkhe-068a86252'} profileimage={bdexec5} profilename={"Nandini Bodkhe"} profilerole={"Business Executive"} />
                </div>
            </div>
            <div>
            <CardView socialtwitter={'https://twitter.com/Janhvi2794?t=Khn6-IlEBFQGZB7kUjnmpw&s=09'} sociallinkdin={'https://www.linkedin.com/in/janhvi-jha-804a65289'} profileimage={bdexec6} profilename={'Janhvi Jha'} profilerole={'Business Executive'} />
                <CardView socialtwitter={'https://twitter.com/nutFL1X'} sociallinkdin={'https://www.linkedin.com/in/manish-marndi-4bb299288/'} profileimage={bdexec7} profilename={'Manish Marndi'} profilerole={'Business Executive'} />
                <CardView socialtwitter={'https://twitter.com/aditpandeyy'} sociallinkdin={'https://www.linkedin.com/in/aditpandeyy'} profileimage={bdexec8} profilename={'Aditya Pandey'} profilerole={'Business Executive'} />
            </div>

            <hr />

            {/* Editor Team */}
            <div>
                <CardView socialtwitter={'https://twitter.com/Himansu_gh?t=Or0uWUoI_nLMMRQRpBCsPA&s=08'} sociallinkdin={'https://www.linkedin.com/in/himansu-sekhar-sahoo-2a0051232'} profileimage={editor1} profilename={"Himansu Sahoo"} profilerole={"Editor in Chief"} />
                {/* <CardView socialtwitter={'https://twitter.com/yaashhpatil?t=eEL5PoXCH0nCYd62UWPbRg&s=09'} sociallinkdin={'https://www.linkedin.com/in/yash-patil-844388221'} profileimage={editorhead} profilename={"Yash Patil"} profilerole={"Editor in Chief"} /> */}
                <CardView socialtwitter={'https://twitter.com/Shivam_Modhave'} sociallinkdin={'https://www.linkedin.com/in/shivam-modhave-142b31220'} profileimage={editor2} profilename={"Shivam Modhave"} profilerole={"Editor"} />
                <CardView socialtwitter={'https://twitter.com/RohanGh30819576?t=G0vF7pPBfKLUeCfKdaLoeA&s=08'} sociallinkdin={'https://www.linkedin.com/in/rohan-gharat-4084ba280'} profileimage={editor3} profilename={'Rohan Gharat'} profilerole={'Editor'} />
                <CardView socialtwitter={'https://twitter.com/DishaNaik2618?t=2FEbcvp_a5Ycr1c_LyI0RA&s=09'} sociallinkdin={'https://www.linkedin.com/in/disha-naik-319828269'} profileimage={editor4} profilename={'Disha Naik '} profilerole={'Editor'} />
            </div>
            <hr />

            {/* Developer Team */}
            <div>
                {/* <CardView socialtwitter={'https://twitter.com/SamiAhmed541'} sociallinkdin={'https://www.linkedin.com/in/sami-ahmed-5602301b2/'} profileimage={devhead} profilename={"Sami Ahmed"} profilerole={"Developer Team Head"} /> */}
                {/* <CardView socialtwitter={''} sociallinkdin={''} profileimage={appdev} profilename={"Karan Savaliya"} profilerole={"Android Developer"} /> */}
                {/* <CardView socialtwitter={'https://mobile.twitter.com/Rajat__Rai'} sociallinkdin={'https://www.linkedin.com/in/rajat-rai-96298222b'} profileimage={webdev} profilename={"Rajat Rai"} profilerole={"Developer Team Head"} /> */}
                <CardView socialtwitter={'https://twitter.com/prathameshdk02'} sociallinkdin={'https://www.linkedin.com/in/prathamesh-kadve-9948ba232'} profileimage={jtwebdev} profilename={"Prathamesh Kadve"} profilerole={"Developer Team Head"} />
                <CardView socialtwitter={'https://twitter.com/vinyasvasant'} sociallinkdin={'https://www.linkedin.com/in/vinyashegde'} profileimage={devteam1} profilename={'Vinyas Hegde'} profilerole={'Developer Team Member'} />
                <CardView socialtwitter={'https://twitter.com/swapnil18901'} sociallinkdin={'https://www.linkedin.com/in/swapnil-chavan-0a1a95214'} profileimage={devteam2} profilename={'Swapnil Chavan'} profilerole={'Developer Team Member'} />
            </div>
        </div>
    )

}

export default Team;
