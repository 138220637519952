import React from 'react'
import CardView from '../components/about/Cards/CardFaculty'
import '../css/Team.css'

import Principal from '../assets/pages/about/team-members/Dr-J-W-Bakal.jpg'
import HOD from '../assets/pages/about/team-members/Ms.-Rohini-Bhosale-1.jpg'
import MunawiraMam from '../assets/pages/about/team-members/munawiramaam.jpg'
import EkataMam from '../assets/pages/about/team-members/Ekata-1.jpg'

function Faculty() {
    return (
        <div className="team">
            <br/>
            <div>
                <CardView  sociallinkdin={""} profileimage={Principal} profilename={"Dr. J. W. Bakal"} profilerole={"Principal"} />
                <CardView  sociallinkdin={"https://www.linkedin.com/in/rohini-bhosale-3b78785a/"} profileimage={HOD} profilename={"Rohini Bhosale"} profilerole={"Head of Department"} subprofilerole={"And Nominee Member"}/>
                 <CardView  sociallinkdin={"https://www.linkedin.com/in/munawira-kotyad-3aa2221b/"} profileimage={MunawiraMam} profilename={"Munawira Kotyad"} profilerole={"Nominee Member"} />
                 <CardView  sociallinkdin={"https://www.linkedin.com/in/ekta-pashine-55492549/"} profileimage={EkataMam} profilename={"Ekta Ukey"} profilerole={"Student Branch Coordinator"} subprofilerole={"And Nominee Member"}/>
            </div>
            <br/><br/><br/>
        </div>
    )
}
export default Faculty
