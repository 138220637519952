import { Button } from '@material-ui/core'
import { LocationOn} from '@material-ui/icons'
import React from 'react'
import './CareerComponent.css'

function CareerComponent() {
    return (
        <div className='CareerComponent'>
             <div
                className="jobcard"
                >
                    <div className='jobheader'>
                        <div className='companyLogoborder'>
                            <img className='companyLogo'
                                        src="https://th.bing.com/th/id/OIP.4yoIuxOnSsGLqxaigYommgAAAA?w=145&h=150&c=7&r=0&o=5&dpr=1.38&pid=1.7"
                        />
                        
                        </div>
                        <div style={{
                                marginLeft:'10px'
                            }}> 
                            <h4  className='jobHeadline'>Full Stack Web Developer</h4>
                            <h6 className='companyName'>DoubtConnect Edtech Pvt Ltd</h6>
                            <p style={{
                                    display:'flex',alignItems:'center',fontWeight:'400'
                            }}>
                                <LocationOn className='loc'/> Remote
                            </p>
                        </div>
                    </div> 
                <Button
                    href={'https://forms.gle/FFCEcyuBbRDt5k5S9'}
                    target="blank"
                    className="applybtn"
                    shape="round"
                    variant="inherit" color="Primary"
                    fullWidth>
                    Apply
                </Button>
               
            </div>
            
            <div
                className="jobcard"
                >
                    <div className='jobheader'>
                        <div className='companyLogoborder'>
                            <img className='companyLogo'
                                        src="https://media-exp1.licdn.com/dms/image/C560BAQGX1jncXnTKeQ/company-logo_200_200/0/1519874083006?e=1652918400&v=beta&t=-cG0wMDZf8kFW4p815iJzFRRa7ycjtAjrJLcPT4Ey08"
                        />
                        
                        </div>
                        <div style={{
                                marginLeft:'10px'
                            }}> 
                            <h4  className='jobHeadline'>Android Developer</h4>
                            <h6 className='companyName'>CRKB IT Solutions Pvt. Ltd.</h6>
                            <p style={{
                                    display:'flex',alignItems:'center',fontWeight:'400'
                            }}>
                                <LocationOn className='loc'/> Remote
                            </p>
                        </div>
                    </div> 
                <Button
                    href={'https://www.linkedin.com/jobs/view/2919570339'}
                    target="blank"
                    className="applybtn"
                    shape="round"
                    variant="inherit" color="Primary"
                    fullWidth>
                    Apply
                </Button>
               
            </div>

            <div
                className="jobcard"
                >
                    <div className='jobheader'>
                        <div className='companyLogoborder'>
                            <img className='companyLogo'
                                        src="https://angel.co/assets/shared/angellist_logo_hand-242554a1b6fa9f574f4ee1488f18d011b3066c90989f8320c15961aedaada679.svg"
                        />
                        
                        </div>
                        <div style={{
                                marginLeft:'10px'
                            }}> 
                            <h4  className='jobHeadline'>Software Engineering Intern</h4>
                            <h6 className='companyName'>BluCap Health Technologies</h6>
                            <p style={{
                                    display:'flex',alignItems:'center',fontWeight:'400'
                            }}>
                                <LocationOn className='loc'/> Remote
                            </p>
                        </div>
                    </div> 
                <Button
                    href={'https://angel.co/jobs?job_listing_id=1930989'}
                    target="blank"
                    className="applybtn"
                    shape="round"
                    variant="inherit" color="Primary"
                    fullWidth>
                    Apply
                </Button>
               
            </div>

            <div
                className="jobcard"
                >
                    <div className='jobheader'>
                        <div className='companyLogoborder'>
                            <img className='companyLogo'
                                        src="https://media-exp1.licdn.com/dms/image/C4D0BAQHW7_AM5T9BHg/company-logo_200_200/0/1641195008008?e=1652918400&v=beta&t=0bkvwC0LiPHxowAR3IBtxKIl6Tw-7OtiCayBLW_8hDY"
                        />
                        
                        </div>
                        <div style={{
                                marginLeft:'10px'
                            }}> 
                            <h4  className='jobHeadline'>Android Native Developer</h4>
                            <h6 className='companyName'>MentorPlus</h6>
                            <p style={{
                                    display:'flex',alignItems:'center',fontWeight:'400'
                            }}>
                                <LocationOn className='loc'/> Remote
                            </p>
                        </div>
                    </div> 
                <Button
                    href={'https://www.linkedin.com/jobs/view/2912364199/'}
                    target="blank"
                    className="applybtn"
                    shape="round"
                    variant="inherit" color="Primary"
                    fullWidth>
                    Apply
                </Button>
               
            </div>
        </div>
        

        

        
    )
}

export default CareerComponent
