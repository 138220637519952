import React from 'react';
import './TopSection.css';

const TopSection = () => {
  return (
    <section className="top">
      <div className="topContainer">
        <h1>Together we do great things! </h1>
        <a target="blank" href="https://forms.gle/ks29fTFS8sH9BpRE9" 
             className="joinButton">Join the Club</a>
      </div>
    </section>
  );
};

export default TopSection;
