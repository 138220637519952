import React,{useEffect} from 'react'
import Header from '../components/common/Header/Header'
import DashboardComponent from '../components/Dashboard/DashboardComponent'
import Footer from './Footer'
// import {useLocation} from 'react-router-dom'

function Dashboard(props) {
    // const location = useLocation()
    // useEffect(() => {
    //     console.log(location.pathname)
    //     console.log(location.state)
    // }, [location])
    return (
        <>
            <Header/>
            <DashboardComponent/>
            <Footer/>
        </>
    )
}

export default Dashboard
