import React from 'react';

const Graphicon = () => {
  return (
    <>
      <p><strong>Greetings to all! ✨</strong></p>

      <p>CSI PHCET is kicking off this year's event season! 🔥</p>

      <p>
        <strong>GraphiCon:</strong> Where Creativity Meets Design! 🎨👩‍💻<br /> Accompanied by the <br /><strong> CSI CORE 2023-24 INTERVIEW RESULT
        DECLARATION 🎗🎊</strong>
      </p>

      <p><em>
        <strong>GraphiCon</strong> is a hands-on event where you can directly communicate and learn from the editors and
        graphic designers of the CSI CORE Committee. ✨ Also, <strong>meet the new members of CSI CORE 2023-24! 💫</strong>
        </em>
      </p>

      <p><strong>
        We kindly urge all the candidates who applied for the CSI CORE 2023-24 team to be present during the
        event. 😄</strong>
      </p>

      <p>
        <strong>📌 Rules to be a part of the GraphiCon event:</strong>
        <ul>
          <li><em>1. Exclusive for CSI members only.</em></li>
          <li>2. Participants need to bring their own laptops.</li>
          <li>3. The first 50 members with laptops will be accommodated for the hands-on session.</li>
        </ul>
      </p>

      <p>
        <strong>📌 Apply for the event using the link given below:</strong>
        <br />
        <strong>🖇 Application link:</strong> https://forms.gle/jTpjLZFDhDbeJP9W9
      </p>

      <p>
        <strong>📌 The event will be conducted on:</strong>
        <ul>
          <strong>📆 Date:</strong> Monday, 21st August 2023. <br />
          <strong>🕜 Time:</strong> 2 pm onwards. <br />
          <strong>📍 Venue:</strong> 1st floor, Conclave 1. <br />
        </ul>
      </p>

      <p>
        <strong>📌 Any queries?</strong> 🧐 Contact: <br />
        <em>📱 Hrishikesh Bhilare:</em> +91 91379 72558
      </p>
    </>
  );
};

export default Graphicon;
