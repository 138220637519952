import React from 'react';
import { Element } from 'react-scroll';
import Members from './Members';
import PastMembers from './PastMembers';
import Team from '../../pages/Team';
import PastTeam22 from '../../pages/PastTeam22';
import PastTeam23 from '../../pages/PastTeam23';
import '../../css/Member.css';

const TeamDetail = () => (
    <Element name="volunteer">
        <section className={'volunteer'} id="volunteer">
            <PresentMember />
            <PastMember23 />
            <PastMember22 />
        </section>
    </Element>
);
export default TeamDetail;

const PresentMember = () => (
    <Members title="Our Workforce">
        <Team />
    </Members>
);

const PastMember23 = () => (
    <PastMembers title="2022-23 Team">
        <PastTeam23 />
    </PastMembers>
);

const PastMember22 = () => (
    <PastMembers title="2021-22 Team">
        <PastTeam22 />
    </PastMembers>
);
