import React from 'react'

const PhotoshopWorkshop = () => {
    return (
        <>
            <p>The workshop on  Creative Photoshop by CSI PHCET on 24th April 2021 saw 42 participants eager to learn and enhance their editing skills.</p>
            <p>Topics covered in the workshop are:</p>
            <ul>
                <li>Photoshop and it's importance in the global market</li>
                <li>Basic Features of Adobe Photoshop Software</li>
                <li>Insights about the Automatic Tools of Adobe Photoshop</li>
                <li>To use Filters and smart techniques to enhance the photos</li>
                <li>To create your own posters from scratch</li>
                <li>Tips for Freelancing and Digital Marketing</li>
            </ul>
        </>
    )
}

export default PhotoshopWorkshop;
