import React from 'react';

const EthicalQuest = () => {
    return (
        <>
            <p>
                <b>Warm greetings to all!</b>
            </p>

            <p>
                CSI PHCET branch is excited as well as pleased to announce that we're hosting an workshop on
                Ethical Hacking and Cybersecurity.
            </p>

            <p>
                <b>🛡️👾 ETHICAL QUEST 👨‍💻🔎</b>
            </p>

            <p>
                <b>🗣️ Speaker: Mr. Ajinkya Lohakare (Cyberguru Ajinkya)</b>
                <br /> CTO at Ditto Security | Ethical Hacker| CHFI | LPT | Josh Talk speaker | TEDX speaker |
                PODCASTER.
            </p>

            <p>
                CSI PHCET encourage all the students to attend this workshop to learn about the various
                concepts of Ethical Hacking and Cybersecurity.
            </p>

            <p>
                <b>Note</b>:
                <ul>
                    <li>
                        This event will be a <b>HANDS-ON</b> event. Participants are requested to bring their
                        own laptops to be a part of this event.
                    </li>
                    <li>
                        Only <b>100 seats</b> are available for this event.
                    </li>
                    <li>
                        This workshop is open for all the students of COMP, IT and ECS department of PHCET.
                    </li>
                </ul>
            </p>

            <p>
                📌 Registration Fees:
                <ul>
                    <li>
                        <b>Non-CSI members: ₹250 only.</b>
                    </li>
                    <li>
                        <b>CSI members: ₹200 only.</b>
                    </li>
                </ul>
            </p>

            <p>
                <b>📌 Some of the topics from this workshop are:</b>
                <ul>
                    <li>1. Introduction to Ethical Hacking</li>
                    <li>2. Footprinting and Reconnaissance</li>
                    <li>3. System Hacking</li>
                    <li>4. Developing Ethical Hacking tools with python and much more...</li>
                </ul>
                Aren't the reasons enough and unavoidable❔
            </p>

            <p>
                <b>
                    📌 To register for the event, feel free to press the <em>Register</em> button! <br />
                </b>
            </p>

            <p>
                <b>📌 Event will be conducted on:</b> <br />
                📅 Date: Thursday, 5 October 2023 and Friday, 6 October 2023. <br />
                🕚 Time: 11am to 4pm. <br />
                📍 Venue: Conclave 1 <br />
            </p>

            <p>
                <b>
                    Certificates for the event will be provided to all the participants who complete this 2
                    day workshop by Ditto security. 🎗️👨‍🎓
                </b>
            </p>

            <p>
                <b>CSI PHCET</b> branch awaits your presence with anticipation.✨
            </p>

            <p>
                Cordial regards, <br />
                <b>Team CSI PHCET</b>
            </p>
        </>
    );
};

export default EthicalQuest;
