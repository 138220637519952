import React from 'react'

const JavaWorkshop = () => {
    return (
        <>
            <p>CSI PHCET organized a peer-to-peer event on Java Programming on the 25th of April, 2021. The idea was to share knowledge and information among peers to cultivate a comfortable and interactive learning environment. This could be seen as a part of our goal “For the students, By the students.”</p>
            <p>    This event was conducted by Parth Pitrubhakta who is a Second year student from the Computer Department. The event saw a large number of participants who showed great interest and helped in keeping the session interactive.</p>
            <p>  The topics covered in this session were:</p>
            <ul>
                <li>Introduction to Java</li>
                <li>OOP concepts supported by java which include Inheritance, Polymorphism, Data Abstraction and Data Encapsulation.</li>
                <li>What are variables and their syntax and different datatypes used in Java</li>
                <li>Arithmetic operators, Assignment operators, Comparison operators and logical operators along with their examples</li>
                <li>Math handling functions in Java</li>
                <li>How to take user input using command line arguments and scanner classes</li>
                <li>Constructors and its types along with arrays</li>
                <li>Concepts of Method overloading, method overriding and Inheritance</li>
                <li>Valuable information about certification courses the participants will find useful</li>
                <li>GUI on NetBeans IDE and insights on GitHub.</li>
            </ul>
        </>
    )
}

export default JavaWorkshop;
