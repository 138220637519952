import React, { useState } from 'react';
import './css/App.css';
import { windowSupported } from './utils/checkSupport';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './utils/PrivateRoute';

// Pages
import HomePage from './pages/Home';
import Resources from './pages/Resources';
import Contribute from './pages/Contribute';
import About from './pages/About';
import Events from './pages/Events';
import EventDetail from './EventDetail';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Career from './pages/Career';

// Event Posters
import EthicalHacking from './assets/pages/events-Poster/ethicalhacking.jpg';
import Python from './assets/pages/events-Poster/python.jpg';
import CompetitiveProgramming from './assets/pages/events-Poster/competitiveprogramming.jpg';
import PhotoShop from './assets/pages/events-Poster/photoshop.jpg';
import MockApti1 from './assets/pages/events-Poster/mockapti1.jpg';
import MockApti2 from './assets/pages/events-Poster/mockapti2.jpg';
import Java from './assets/pages/events-Poster/java.jpg';
import Codecrush from './assets/pages/events-Poster/codecrush.jpg';
import Github from './assets/pages/events-Poster/git-github.jpg';
import ReactJS from './assets/pages/events-Poster/reactjs.jpg';
import ChillWithCsi from './assets/pages/events-Poster/ChillWithCsi.jpg';
import Mlusingtf from './assets/pages/events-Poster/MlUsingTf.jpg';
import PcAssemble from './assets/pages/events-Poster/pcassembly.jpg';
import Funfriday from './assets/pages/events-Poster/funfriday.jpg';
import iot from './assets/pages/events-Poster/iot.jpg';
import iot_2 from './assets/pages/events-Poster/iot2.jpg';
import iot_3 from './assets/pages/events-Poster/iot3.jpg';
import CsiUiImg from './assets/pages/events-Poster/csiui.jpg';
import Graphicon from './assets/pages/events-Poster/graphicon.png';
import GitFusion from './assets/pages/events-Poster/gitfusion.png';
import EthicalQuest from './assets/pages/events-Poster/ethicalquest.jpg'
import MjunctionImg from './assets/pages/events-Poster/mentorshipjunction.jpg'
import ThinkaiImg from './assets/pages/events-Poster/thinkai.jpg'
import InsightImg from './assets/pages/events-Poster/internInsight.jpg';

// Event-Report
import EHackinhWorkshop from './assets/pages/events-report/ethical';
import PythonWorkshop from './assets/pages/events-report/python';
import CPWorkshop from './assets/pages/events-report/cp';
import PhotoshopWorkshop from './assets/pages/events-report/photoshop';
import JavaWorkshop from './assets/pages/events-report/java';
import CodeCrush from './assets/pages/events-report/codecrush';
import MockAptitude1 from './assets/pages/events-report/mockapti1';
import MockAptitude2 from './assets/pages/events-report/mockapti2';
import GitHubWorkshop from './assets/pages/events-report/github';
import ReactJs from './assets/pages/events-report/reactjs';
import ChillwithCsi from './assets/pages/events-report/chillwithcsi';
import MlusingTf from './assets/pages/events-report/mlusingtf';
import PcAssembly from './assets/pages/events-report/pcassemble';
import FunFriday from './assets/pages/events-report/funfriday';
import Iot from './assets/pages/events-report/iot';
import Iot2 from './assets/pages/events-report/iot2';
import Iot3 from './assets/pages/events-report/iot3';
import CsiUi from './assets/pages/events-report/CsiUi';
import GraphiconRep from './assets/pages/events-report/Graphicon';
import GitFusionRep from './assets/pages/events-report/GitFusion';
import EthicalQuestRep from './assets/pages/events-report/EthicalQuest';
import Mjunction from './assets/pages/events-report/mjunction';
import Thinkai from './assets/pages/events-report/thinkai';
import InternInsight from './assets/pages/events-report/InternInsight';


// Event Images
import mlUsingtflite1 from './assets/pages/event-Images/mlusingtflite1.jpg';
import mlUsingtflite2 from './assets/pages/event-Images/mlusingtflite2.jpg';
import mlUsingtflite3 from './assets/pages/event-Images/mlusingtflite3.jpg';
import webUsingreactjs1 from './assets/pages/event-Images/webusingreactjs1.jpg';
import webUsingreactjs2 from './assets/pages/event-Images/webusingreactjs2.jpg';
import webUsingreactjs3 from './assets/pages/event-Images/webusingreactjs3.jpg';
import webUsingreactjs4 from './assets/pages/event-Images/webusingreactjs4.jpg';
import webUsingreactjs5 from './assets/pages/event-Images/webusingreactjs5.jpg';
import chillWithcsi1 from './assets/pages/event-Images/chillwithcsi1.jpg';
import chillWithcsi2 from './assets/pages/event-Images/chillwithcsi2.jpg';
import chillWithcsi3 from './assets/pages/event-Images/chillwithcsi3.jpg';
import chillWithcsi4 from './assets/pages/event-Images/chillwithcsi4.jpg';
import pcAssembly1 from './assets/pages/event-Images/pcassembly1.jpg';
import pcAssembly2 from './assets/pages/event-Images/pcassembly2.jpg';
// import pcAssembly3 from './assets/pages/event-Images/pcassembly3.jpg'
import funFriday1 from './assets/pages/event-Images/funfriday1.jpg';
import funFriday2 from './assets/pages/event-Images/funfriday2.jpg';
import ethicalHacking1 from './assets/pages/event-Images/ethicalHacking1.jpg';
import ethicalHacking2 from './assets/pages/event-Images/ethicalHacking2.jpg';
import ethicalHacking3 from './assets/pages/event-Images/ethicalHacking3.jpg';
import python1 from './assets/pages/event-Images/python1.jpg';
import python2 from './assets/pages/event-Images/python2.jpg';
import python3 from './assets/pages/event-Images/python3.jpg';
import python4 from './assets/pages/event-Images/python4.jpg';
import cp1 from './assets/pages/event-Images/cp1.jpg';
import cp2 from './assets/pages/event-Images/cp2.jpg';
import cp3 from './assets/pages/event-Images/cp3.jpg';
import photoshop1 from './assets/pages/event-Images/photoshop1.jpg';
import photoshop2 from './assets/pages/event-Images/photoshop2.jpg';
import photoshop3 from './assets/pages/event-Images/photoshop3.jpg';
import java1 from './assets/pages/event-Images/java1.jpg';
import java2 from './assets/pages/event-Images/java2.jpg';
import java3 from './assets/pages/event-Images/java3.jpg';
import codecrush1 from './assets/pages/event-Images/codecrush1.jpg';
import codecrush2 from './assets/pages/event-Images/codecrush2.jpg';
import codecrush3 from './assets/pages/event-Images/codecrush3.jpg';
import codecrush4 from './assets/pages/event-Images/codecrush4.jpg';
import git1 from './assets/pages/event-Images/git1.jpg';
import git2 from './assets/pages/event-Images/git2.jpg';
import git3 from './assets/pages/event-Images/git3.jpg';
import mockapti1a from './assets/pages/event-Images/mockapti1a.jpg';
import mockapti1b from './assets/pages/event-Images/mockapti1b.jpg';
import mockapti1c from './assets/pages/event-Images/mockapti1c.jpg';
import mockapti2a from './assets/pages/event-Images/mockapti2a.jpg';
import mockapti2b from './assets/pages/event-Images/mockapti2b.jpg';
import mockapti2c from './assets/pages/event-Images/mockapti2c.jpg';
import mockapti2d from './assets/pages/event-Images/mockapti2d.jpg';
import iot1 from './assets/pages/event-Images/iot1.jpg';
import iot2 from './assets/pages/event-Images/iot2.jpg';
import iot3 from './assets/pages/event-Images/iot3.jpg';
import iot2a from './assets/pages/event-Images/iot2a.jpg';
import iot2b from './assets/pages/event-Images/iot2b.jpg';
import iot2c from './assets/pages/event-Images/iot2c.jpg';
import iot2d from './assets/pages/event-Images/iot2d.jpg';
import iot2e from './assets/pages/event-Images/iot2e.jpg';
import iot2f from './assets/pages/event-Images/iot2f.jpg';
import iot2g from './assets/pages/event-Images/iot2g.jpg';
import iot3a from './assets/pages/event-Images/iot3a.jpg';
import iot3b from './assets/pages/event-Images/iot3b.jpg';
import iot3c from './assets/pages/event-Images/iot3c.jpg';
import iot3d from './assets/pages/event-Images/iot3d.jpg';
import graphicon1 from './assets/pages/event-Images/graphicon-1.png';
import graphicon2 from './assets/pages/event-Images/graphicon-2.png';
import graphicon3 from './assets/pages/event-Images/graphicon-3.png';
import gitfusion1 from './assets/pages/event-Images/gitfusion-1.jpg';
import gitfusion2 from './assets/pages/event-Images/gitfusion-2.jpg';
import gitfusion3 from './assets/pages/event-Images/gitfusion-3.jpg';
import gitfusion4 from './assets/pages/event-Images/gitfusion-4.jpg';
import mjunction1 from './assets/pages/event-Images/mjunction1.png';
import mjunction2 from './assets/pages/event-Images/mjunction2.png';
import mjunction3 from './assets/pages/event-Images/mjunction3.png';
import thinkai1 from './assets/pages/event-Images/thinkai1.jpg';
import thinkai2 from './assets/pages/event-Images/thinkai2.jpg';
import thinkai3 from './assets/pages/event-Images/thinkai3.jpg';
import thinkai4 from './assets/pages/event-Images/thinkai4.jpg';
import thinkai5 from './assets/pages/event-Images/thinkai5.jpg';
import insight1 from './assets/pages/event-Images/intern1.png';
import insight2 from './assets/pages/event-Images/intern2.png';
import insight3 from './assets/pages/event-Images/intern3.png';
import insight4 from './assets/pages/event-Images/intern4.png';


//firebase
import firebase from 'firebase/app';
import 'firebase/analytics';
import { AuthProvider } from './utils/auth';

function App() {
  // console.log("firebase apps: ",firebase.apps)
  if (firebase.apps.length === 0) {
    var firebaseConfig = {
      apiKey: 'AIzaSyCo_BJv67-F8eU2M9CJlk_24pLK_uV25F4',
      authDomain: 'csi-phcet-35b11.firebaseapp.com',
      databaseURL: 'https://csi-phcet-35b11-default-rtdb.firebaseio.com',
      projectId: 'csi-phcet-35b11',
      storageBucket: 'csi-phcet-35b11.appspot.com',
      messagingSenderId: '660398073387',
      appId: '1:660398073387:web:677b95fa8204330e23ac6e',
      measurementId: 'G-D3SR32C6F5',
    };
    var fapp = firebase.initializeApp(firebaseConfig);
    var analytics = fapp.analytics();
  }
  const [width, setWidth] = useState(windowSupported() ? window.innerWidth : 0);
  const [EventData, setEventData] = useState([
    {
      url: 'ethical-hacking',
      EventPoster: EthicalHacking,
      EventTitle: 'Webinar on “Ethical Hacking”',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '30th November 2020',
      EventTime: '10:30AM - 12:30PM',
      EventLocation: 'Online',
      upcomingEvent: false,
      EventRegisterationLink: '#',
      EventDetails: <EHackinhWorkshop />,
      EventPhotos: [ethicalHacking1, ethicalHacking2, ethicalHacking3],
    },
    {
      url: 'python',
      EventPoster: Python,
      EventTitle: 'Python Workshop',
      EventOrganizer: 'HPCL',
      EventDate: '6th-7th March 2021',
      EventTime: '10:30AM - 12:30PM',
      EventLocation: 'Online',
      upcomingEvent: false,
      EventRegisterationLink: '#',
      EventDetails: <PythonWorkshop />,
      EventPhotos: [python1, python2, python3, python4],
    },
    {
      url: 'compitetive-programming',
      EventPoster: CompetitiveProgramming,
      EventTitle: 'Competitive Programming',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '10th-11 April 2021',
      EventTime: '10:30AM - 12:30PM',
      EventLocation: 'Online',
      upcomingEvent: false,
      EventRegisterationLink: '#',
      EventDetails: <CPWorkshop />,
      EventPhotos: [cp1, cp2, cp3],
    },
    {
      url: 'photoshop',
      EventPoster: PhotoShop,
      EventTitle: 'Photoshop Workshop',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '24th April 2021',
      EventTime: '10:30AM - 12:30PM',
      EventLocation: 'Online',
      upcomingEvent: false,
      EventRegisterationLink: '#',
      EventDetails: <PhotoshopWorkshop />,
      EventPhotos: [photoshop1, photoshop2, photoshop3],
    },
    {
      url: 'java',
      EventPoster: Java,
      EventTitle: 'Java Workshop',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '25th April 2021',
      EventTime: '10:30AM - 12:30PM',
      EventLocation: 'Online',
      upcomingEvent: false,
      EventRegisterationLink: '#',
      EventDetails: <JavaWorkshop />,
      EventPhotos: [java1, java2, java3],
    },
    {
      url: 'codecrush',
      EventPoster: Codecrush,
      EventTitle: 'Code Crush Competition',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '2nd May 2021',
      EventTime: '11:00 AM',
      EventLocation: 'Online',
      upcomingEvent: false,
      EventRegisterationLink: '#',
      EventDetails: <CodeCrush />,
      EventPhotos: [codecrush1, codecrush2, codecrush3, codecrush4],
    },
    {
      url: 'mockaptitude1',
      EventPoster: MockApti1,
      EventTitle: 'Mock Aptitude Test 1',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '18th July 2021',
      EventTime: '6:00PM',
      EventLocation: 'Online',
      upcomingEvent: false,
      EventRegisterationLink: '#',
      EventDetails: <MockAptitude1 />,
      EventPhotos: [mockapti1a, mockapti1b, mockapti1c],
    },
    {
      url: 'mockaptitude2',
      EventPoster: MockApti2,
      EventTitle: 'Mock Aptitude Test 2',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '25th July 2021',
      EventTime: '12:00PM',
      EventLocation: 'Online',
      upcomingEvent: false,
      EventRegisterationLink: '#',
      EventDetails: <MockAptitude2 />,
      EventPhotos: [mockapti2a, mockapti2b, mockapti2c, mockapti2d],
    },
    {
      url: 'github',
      EventPoster: Github,
      EventTitle: 'Git & Github Workshop',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '31st July 2021',
      EventTime: '4:00PM',
      EventLocation: 'Online',
      upcomingEvent: false,
      EventRegisterationLink: 'https://forms.gle/aMf8hwi8iT2FZ3Cq8',
      EventDetails: <GitHubWorkshop />,
      EventPhotos: [git1, git2, git3],
    },
    {
      url: 'web-development',
      EventPoster: ReactJS,
      EventTitle: 'Learn Web Development using React JS',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '21st August 2021',
      EventTime: '5:00PM',
      EventLocation: 'Online',

      upcomingEvent: false,
      EventRegisterationLink: 'https://forms.gle/mABzKwDcbWApX6js9',
      EventDetails: <ReactJs />,
      EventPhotos: [webUsingreactjs1, webUsingreactjs2, webUsingreactjs3, webUsingreactjs4, webUsingreactjs5],
    },
    {
      url: 'chill-with-csi',
      EventPoster: ChillWithCsi,
      EventTitle: 'Chill with Csi',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '5th September 2021',
      EventTime: '5:00PM',
      EventLocation: 'Online',

      upcomingEvent: false,
      EventRegisterationLink: 'https://forms.gle/VjdN31d8e71prKpTA',
      EventDetails: <ChillwithCsi />,
      EventPhotos: [chillWithcsi1, chillWithcsi2, chillWithcsi3, chillWithcsi4],
    },
    {
      url: 'ml-using-tf',
      EventPoster: Mlusingtf,
      EventTitle: 'On Device Machine Learning Using TensorFlow Lite',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '𝟭𝟱𝘁𝗵 𝗝𝗮𝗻𝘂𝗮𝗿𝘆 𝟮𝟬𝟮𝟮(𝗦𝗮𝘁𝘂𝗿𝗱𝗮𝘆) ',
      EventTime: '𝟰:𝟬𝟬 𝗣𝗠 𝗼𝗻𝘄𝗮𝗿𝗱𝘀',
      EventLocation: '𝗚𝗼𝗼𝗴𝗹𝗲 𝗠𝗲𝗲𝘁',
      upcomingEvent: false,
      EventRegisterationLink: 'https://forms.gle/6ZQZaE7Rg2RgrV4v6',
      EventDetails: <MlusingTf />,
      EventPhotos: [mlUsingtflite1, mlUsingtflite2, mlUsingtflite3],
    },
    {
      url: 'pc-assembly',
      EventPoster: PcAssemble,
      EventTitle: '𝗣𝗖 𝗔𝗦𝗦𝗘𝗠𝗕𝗟𝗬 𝗪𝗢𝗥𝗞𝗦𝗛𝗢𝗣 𝘄𝗶𝘁𝗵 𝗖𝗦𝗜 𝗣𝗛𝗖𝗘𝗧',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '13th April 2022, (Monday)',
      EventTime: '2:30 PM',
      EventLocation: 'Computer Department Lab',
      upcomingEvent: false,
      EventRegisterationLink: '#',
      EventDetails: <PcAssembly />,
      EventPhotos: [pcAssembly1, pcAssembly2],
    },
    {
      url: 'fun-friday',
      EventPoster: Funfriday,
      EventTitle: 'Fun Friday With CSI',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '11th March 2022',
      EventTime: '12:30 PM',
      EventLocation: 'Artificial Lawn',
      upcomingEvent: false,
      EventRegisterationLink: '#',
      EventDetails: <FunFriday />,
      EventPhotos: [funFriday1, funFriday2],
    },

    {
      url: 'iot',
      EventPoster: iot,
      EventTitle: '𝗧𝗘𝗖𝗛 𝗧𝗛𝗥𝗜𝗩𝗘: 𝗜𝗻𝘁𝗲𝗿𝗻𝗲𝘁 𝗼𝗳 𝗧𝗵𝗶𝗻𝗴𝘀 (𝗖𝗵𝗮𝗽𝘁𝗲𝗿 𝟭)',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '𝟮𝟯𝗿𝗱 𝗔𝘂𝗴𝘂𝘀𝘁, 𝟮𝟬𝟮𝟮 (𝗧𝘂𝗲𝘀𝗱𝗮𝘆) ',
      EventTime: '𝟯:𝟬𝟬 𝗣𝗠',
      EventLocation: '𝗖𝗼𝗻𝗰𝗹𝗮𝘃𝗲 𝟮',
      upcomingEvent: false,
      EventRegisterationLink: '#',
      EventDetails: <Iot />,
      EventPhotos: [iot1, iot2, iot3],
    },

    {
      url: 'iot2',
      EventPoster: iot_2,
      EventTitle: '𝗧𝗘𝗖𝗛 𝗧𝗛𝗥𝗜𝗩𝗘: 𝗜𝗻𝘁𝗲𝗿𝗻𝗲𝘁 𝗼𝗳 𝗧𝗵𝗶𝗻𝗴𝘀 (𝗖𝗵𝗮𝗽𝘁𝗲𝗿 2)',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '𝟮𝟭𝘀𝘁 𝗦𝗲𝗽𝘁𝗲𝗺𝗯𝗲𝗿 𝟮𝟬𝟮𝟮',
      EventTime: '𝟭:𝟰𝟱 𝗣𝗠',
      EventLocation: '𝗗𝟯𝟭𝟯 𝗟𝗮𝗯',
      upcomingEvent: false,
      EventRegisterationLink: '#',
      EventDetails: <Iot2 />,
      EventPhotos: [iot2a, iot2b, iot2c, iot2d, iot2e, iot2f, iot2g],
    },

    {
      url: 'iot3',
      EventPoster: iot_3,
      EventTitle: '𝗧𝗘𝗖𝗛 𝗧𝗛𝗥𝗜𝗩𝗘: 𝗜𝗻𝘁𝗲𝗿𝗻𝗲𝘁 𝗼𝗳 𝗧𝗵𝗶𝗻𝗴𝘀 (𝗖𝗵𝗮𝗽𝘁𝗲𝗿 3)',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '3rd October 𝟮𝟬𝟮𝟮',
      EventTime: '𝟭:𝟰𝟱 𝗣𝗠',
      EventLocation: '𝗖𝗼𝗻𝗰𝗹𝗮𝘃𝗲 𝗜𝗜 (𝟯𝗿𝗱 𝗳𝗹𝗼𝗼𝗿 )',
      upcomingEvent: false,
      EventRegisterationLink: '#',
      EventDetails: <Iot3 />,
      EventPhotos: [iot3a, iot3b, iot3c, iot3d],
    },

    {
      url: 'csiUi',
      EventPoster: CsiUiImg,
      EventTitle: 'The Art of UI/UX',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '13th March, 2023(Monday)',
      EventTime: '1:30PM onwards',
      EventLocation: 'D313 Lab',
      upcomingEvent: false,
      EventRegisterationLink: '#',
      EventDetails: <CsiUi />,
      EventPhotos: [],
    },

    {
      url: 'graphicon',
      EventPoster: Graphicon,
      EventTitle: 'Graphicon',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '21st August, 2023(Monday)',
      EventTime: '2:00PM onwards',
      EventLocation: 'Conclave 1',
      upcomingEvent: false,
      EventRegisterationLink: '#',
      EventDetails: <GraphiconRep />,
      EventPhotos: [graphicon1, graphicon2, graphicon3],
    },

    {
      url: 'gitfusion',
      EventPoster: GitFusion,
      EventTitle: 'GitFusion',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '14th September, 2023(Thursday)',
      EventTime: '2:30PM onwards',
      EventLocation: 'D313 Lab',
      upcomingEvent: false,
      EventRegisterationLink: '#',
      EventDetails: <GitFusionRep />,
      EventPhotos: [gitfusion1, gitfusion2, gitfusion3, gitfusion4],
    },

    {
      url: 'ethicalquest',
      EventPoster: EthicalQuest,
      EventTitle: 'Ethical Quest',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '5th & 6th October, 2023(Thursday & Friday)',
      EventTime: '11am to 4pm',
      EventLocation: 'Conclave 1',
      upcomingEvent: true,
      EventRegisterationLink: 'https://forms.gle/EwK46m4FxC1qPHcW9',
      EventDetails: <EthicalQuestRep />,
      EventPhotos: [],
    },
    {
      url: 'mjunction',
      EventPoster: MjunctionImg,
      EventTitle: 'Mentorship Junction',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '24th March, 2024',
      EventTime: '11am',
      EventLocation: 'Google Meet',
      upcomingEvent: true,
      // EventRegisterationLink: 'https://forms.gle/EwK46m4FxC1qPHcW9',
      EventDetails: <Mjunction />,
      EventPhotos: [mjunction1,mjunction2,mjunction3],
    },
    {
      url: 'thinkai',
      EventPoster: ThinkaiImg,
      EventTitle: 'ThinkAI',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: ' 24th Jan 2024',
      EventTime: '10:30 am',
      EventLocation: 'Conclave 1',
      upcomingEvent: true,
      EventRegisterationLink: 'https://forms.gle/vDnZY2SPtRHwme2FA',
      EventDetails: <Thinkai />,
      EventPhotos: [thinkai1,thinkai2,thinkai3,thinkai4,thinkai5],
    },
    {
      url: 'insight',
      EventPoster: InsightImg,
      EventTitle: 'Intern Insight',
      EventOrganizer: 'CSI PHCET Student Chapter',
      EventDate: '21 December 2023',
      EventTime: '3-4:30 pm',
      EventLocation: 'Google meet ',
      upcomingEvent: true,
      // EventRegisterationLink: 'https://forms.gle/vDnZY2SPtRHwme2FA',
      EventDetails: <InternInsight/>,
      EventPhotos: [insight1,insight2,insight3,insight4],
    }
  ]);
  return (
    <div className="app">
      <AuthProvider>
        <Router>
          <Switch>
            {EventData.map((eachEvent) => (
              <Route exact path={`/events/${eachEvent.url}`}>
                <EventDetail eachEvent={eachEvent} />
              </Route>
            ))}
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/careers" component={Career} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/contribute" component={Contribute} />
            <Route exact path="/resources" component={Resources} />
            <Route exact path="/events" component={Events} />
            <Route exact path="/about" component={About} />
            <Route exact path="/" component={HomePage} width={width} />
          </Switch>
        </Router>
      </AuthProvider>
    </div>
  );
}
export default App;
