import React from 'react';

const ThinkAI = () => {
    return (
        <>
            <p>✨𝗚𝗿𝗲𝗲𝘁𝗶𝗻𝗴𝘀✨</p>
            <p>Are you curious about the magic behind self-driving cars, recommendation systems, and virtual assistants? 🧐🚘</p>

            <p>💥𝗠𝗘𝗡𝗧𝗢𝗥𝗦𝗛𝗜𝗣 JUNCTION : Student 𝗚𝘂𝗶𝗱𝗮𝗻𝗰𝗲 𝘀𝗲𝗿𝗶𝗲𝘀💥</p>

            <p>
                𝗖𝗦𝗜 𝗣𝗛𝗖𝗘𝗧 cordially invites you to participate in this exciting, hands-on learning experience:

                🔥 ThinkAI: Exploring the depths of AIML 🚀💥
            </p>

            <p>Get ready to embark on a coding adventure that will reshape the way you think about Artificial Intelligence and Machine Learning! 🤖✨</p>

            <p>🗣𝗦𝗽𝗲𝗮𝗸𝗲𝗿: Mr. Hitesh Humane</p>

            <p>
                📌𝗥𝗲𝗾𝘂𝗶𝗿𝗲𝗺𝗲𝗻𝘁𝘀 𝗮𝗿𝗲 𝗮𝘀 𝗳𝗼𝗹𝗹𝗼𝘄𝘀:
                <ul>
                    <li>1.𝗘𝘅𝗰𝗹𝘂𝘀𝗶𝘃𝗲 for 𝗖𝗦𝗜 members.</li>
                    <li>2.Seating is 𝗹𝗶𝗺𝗶𝘁𝗲𝗱, with priority for the first 𝟲𝟬 𝗺𝗲𝗺𝗯𝗲𝗿𝘀 to have registered.</li>
                </ul>
            </p>

            <p>🏆𝗖𝗲𝗿𝘁𝗶𝗳𝗶𝗰𝗮𝘁𝗲𝘀 will be 𝗽𝗿𝗼𝘃𝗶𝗱𝗲𝗱 𝘁𝗼 𝗮𝗹𝗹 𝗽𝗮𝗿𝘁𝗶𝗰𝗶𝗽𝗮𝗻𝘁𝘀 for your active participation.</p>

            <p>
                📅 𝗗𝗮𝘁𝗲: 24th Jan, 2024 <br />
                🕑 𝗧𝗶𝗺𝗲: 10:30 am onwards <br />
                📍 𝗩𝗲𝗻𝘂𝗲:  Conclave 1 <br />
            </p>

            <p>
                Entry fee:
                🔹Free for CSI members
                🔹₹30/- for Non-CSI members</p>

            <p>
                🎯𝗙𝗼𝗿 𝗮𝗻𝘆 𝗳𝘂𝗿𝘁𝗵𝗲𝗿 𝗾𝘂𝗲𝗿𝗶𝗲𝘀, 𝗱𝗼 𝗻𝗼𝘁 𝗵𝗲𝘀𝗶𝘁𝗮𝘁𝗲 𝘁𝗼 𝗿𝗲𝗮𝗰𝗵 𝗼𝘂𝘁 𝘁𝗼 𝗼𝘂𝗿 𝗰𝗼𝗼𝗿𝗱𝗶𝗻𝗮𝘁𝗲𝘀:
                <br />
                📞Tanisha Paras: +91 8591121224<br />
                📞Tanay Jadhav : +91 8879013023 <br />
            </p>

            <p>🚀 Let's code the future together! 💻</p>

            <p>
                Warm regards,
                <br />
                𝗧𝗲𝗮𝗺 𝗖𝗦𝗜 𝗣𝗛𝗖𝗘𝗧
            </p>
        </>
    );
};

export default ThinkAI;
