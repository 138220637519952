import React from 'react'

function MlUsingTf() {
    return (
        <>
           <p>
              A hands-on workshop event called On Device Machine Learning Using Tensorflow Lite was organized by the CSI PHCET on 15th January 2022. At the event, Mr. Sunit Roy served as the keynote speaker. He’s a freelance software developer, Kolkata Twitter Developer community lead and one of the Google Summer of Code participants at Tensorflow. 
           </p>
           <p>
             In order to make the event engaging for students, we offered a fun, hands-on event where they could learn about Machine Learning using TensorFlow Lite.
           </p>
           <p>
             The speaker shared plenty of valuable information with the audience, including tips and tricks. It was indeed an interactive session that offered many learning opportunities. As a final note, giveaway winners were also announced. Participants demonstrated a high level of energy at the event. They all appeared to have a great time.

           </p>
          
        </>
    )
}

export default MlUsingTf;
