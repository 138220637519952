import React from 'react';

const GitFusion = () => {
  return (
    <>
      <p>✨𝗚𝗿𝗲𝗲𝘁𝗶𝗻𝗴𝘀✨</p>
      <p>𝗖𝗦𝗜 𝗣𝗛𝗖𝗘𝗧 cordially invites you to participate in this exciting, hands-on learning experience:</p>

      <p>🚀💥𝗚𝗶𝘁𝗙𝘂𝘀𝗶𝗼𝗻: 𝗕𝗿𝗶𝗱𝗴𝗶𝗻𝗴 𝗗𝗲𝘃𝗲𝗹𝗼𝗽𝗲𝗿𝘀 & 𝗥𝗲𝗽𝗼𝘀𝗶𝘁𝗼𝗿𝗶𝗲𝘀!🔥</p>

      <p>
        Here you'll get to discover seamless 𝗽𝗿𝗼𝗷𝗲𝗰𝘁 𝗰𝗼𝗹𝗹𝗮𝗯𝗼𝗿𝗮𝘁𝗶𝗼𝗻 𝘁𝗲𝗰𝗵𝗻𝗶𝗾𝘂𝗲𝘀, 𝗽𝗿𝗼-𝗹𝗲𝘃𝗲𝗹 𝗚𝗶𝘁𝗛𝘂𝗯 𝗵𝗮𝗰𝗸𝘀, 𝘃𝗲𝗿𝘀𝗶𝗼𝗻
        𝗰𝗼𝗻𝘁𝗿𝗼𝗹 𝗺𝗮𝘀𝘁𝗲𝗿𝘆 and much more!!
      </p>

      <p>🗣𝗦𝗽𝗲𝗮𝗸𝗲𝗿: 𝗠𝗿. 𝗥𝗶𝘀𝗵𝗮𝗯𝗵 𝗠𝗲𝗵𝘁𝗮</p>

      <p>
        📌𝗥𝗲𝗾𝘂𝗶𝗿𝗲𝗺𝗲𝗻𝘁𝘀 𝗮𝗿𝗲 𝗮𝘀 𝗳𝗼𝗹𝗹𝗼𝘄𝘀:
        <ul>
          <li>1.𝗘𝘅𝗰𝗹𝘂𝘀𝗶𝘃𝗲 for 𝗖𝗦𝗜 members.</li>
          <li>2.Seating is 𝗹𝗶𝗺𝗶𝘁𝗲𝗱, with priority for the first 𝟲𝟬 𝗺𝗲𝗺𝗯𝗲𝗿𝘀 to have registered.</li>
        </ul>
      </p>

      <p>🏆𝗖𝗲𝗿𝘁𝗶𝗳𝗶𝗰𝗮𝘁𝗲𝘀 will be 𝗽𝗿𝗼𝘃𝗶𝗱𝗲𝗱 𝘁𝗼 𝗮𝗹𝗹 𝗽𝗮𝗿𝘁𝗶𝗰𝗶𝗽𝗮𝗻𝘁𝘀 for your active participation.</p>

      <p>
        📌To secure your participation, complete the application process through this link:<br /> 🔗𝗔𝗽𝗽𝗹𝗶𝗰𝗮𝘁𝗶𝗼𝗻
        𝗹𝗶𝗻𝗸: https://forms.gle/qR1edWdirKmkLA196 <br />
        📅 𝗗𝗮𝘁𝗲: 14th Sep, 2023 <br />
        🕑 𝗧𝗶𝗺𝗲: 2:30pm onwards <br />
        📍 𝗩𝗲𝗻𝘂𝗲: D313 Lab <br />
      </p>

      <p>
        🎯𝗙𝗼𝗿 𝗮𝗻𝘆 𝗳𝘂𝗿𝘁𝗵𝗲𝗿 𝗾𝘂𝗲𝗿𝗶𝗲𝘀, 𝗱𝗼 𝗻𝗼𝘁 𝗵𝗲𝘀𝗶𝘁𝗮𝘁𝗲 𝘁𝗼 𝗿𝗲𝗮𝗰𝗵 𝗼𝘂𝘁 𝘁𝗼 𝗼𝘂𝗿 𝗰𝗼𝗼𝗿𝗱𝗶𝗻𝗮𝘁𝗲𝘀:
        <br />
        📞 Hrishikesh Bhilare: +91 9137972558 <br />
        📞Tanvi Patil: +91 7249855460 <br />
      </p>

      <p>
        Warm regards,
        <br />
        𝗧𝗲𝗮𝗺 𝗖𝗦𝗜 𝗣𝗛𝗖𝗘𝗧
      </p>
    </>
  );
};

export default GitFusion;
