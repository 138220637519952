import React from 'react'

function ChillWithCsi() {
    return (
        <>
            <p>
                CSI PHCET organized a fun event called Chill with CSI on 5th Sept 2021. Our objective for organizing this event was to let the students have fun, be engaged, and make them interact with the club.

            </p>
            <p>
               The event featured numerous fun games and activities, such as:
            </p>
            <ul>
                <li>Object Hunt</li>
                <li>Guess the Movie</li>
                <li>Quiz on movies</li>
                <li>Guess the Song</li>
                <li>Guess the Price</li>
            </ul>
            <p>
                As part of the games, students were split up into two teams. In the end, Team B won. The event saw a great energy throughout. The students really enjoyed themselves and had a lot of fun.
            </p>
          </> 
    )
}

export default ChillWithCsi;
