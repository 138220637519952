import React from 'react'

function CsiUi() {
    return (
        <>
            <p>
                ✨Greetings✨
            </p>
            <p>

                Team CSI PHCET is back again with an amazing event called: <br />
                <strong>✨The Art of UI/UX✨</strong><br />
            </p>
            <p>
                💡𝐈𝐧𝐬𝐢𝐠𝐡𝐭𝐬 𝐨𝐟 𝐭𝐡𝐢𝐬 𝐞𝐯𝐞𝐧𝐭:
                <p>
                    <ul>
                        <li>UI Designing Knowledge.</li>
                        <li>Hands-on UI building session</li>
                    </ul>
                </p>
            </p>
            <p>
                ✨ 𝗦𝗽𝗲𝗮𝗸𝗲𝗿 𝗼𝗳 𝘁𝗵𝗲 𝗲𝘃𝗲𝗻𝘁:
            </p>
            <p>
                Mrs. Amrita Patel<br />
                Product UX designer at Insorce Interaction designer, graphic designer, Workpees Expert & Frelancer
            </p>
            <p>
                𝗔𝗻𝘆 𝗾𝘂𝗲𝗿𝗶𝗲𝘀???🤔
                Contact us at:
                <ul>
                    <li> 𝐘𝐚𝐬𝐡 𝐌𝐚𝐡𝐚𝐣𝐚𝐧: 80976 32602</li>
                    <li>𝐖𝐞𝐛𝐬𝐢𝐭𝐞: https://csi.phcet.ac.in/
                    </li>
                </ul>
            </p>
            <p>✨ 𝘏𝘰𝘱𝘦 𝘵𝘰 𝘴𝘦𝘦 𝘺𝘰𝘶 𝘢𝘭𝘭!!✨<br />

                Regards,
                𝗧𝗲𝗮𝗺 𝗖𝗦𝗜 𝗣𝗛𝗖𝗘𝗧</p>
        </>
    )
}

export default CsiUi
