import React from 'react';
import './DescriptionSection.css';
import linkedin from '../../../assets/pages/home/Community.jpg';

const DescriptionSection = (props) => {
  return (
    <section className={"description"}>
      <div className={'descriptionTop'}>
        <h2 className={'descriptionHeading'}>Towards A Tech-Driven Future</h2>
        <OpeningParagraph width={props.width} />
        <div className={'descriptionImageContainer'}>
          <img
            className={'descriptionImage'}
            src={linkedin}
            alt="Club members visiting LinkedIn during a club company visit."
          />
        </div>
        {/* eslint-disable-next-line */}
        <div className={'descriptionMission'} role="text">
          <h3 className={'descriptionMissionHeading'}>Our Goal:</h3>
          <MissionContent width={props.width} />
        </div>
      </div>
      <div className={'descriptionMain'}>
        <p>
        The CSI PHCET Developers’ Club offers a wide variety of fun, exciting,
         and beginner-friendly events packed with knowledge and learning experiences
          designed to enrich and grow your understanding of in-demand skills and 
          latest industry trends.  A community of like-minded students and alumni
           ready to tackle anything that comes their way, develop their skills in 
           different aspects of their course and plenty of opportunities for members
            to network and collaborate on projects and hackathons and to develop
             their team-building skills.
        </p>

        <p>
        Whether you're looking to jumpstart your career in Software Engineering,
         Data Science, Cybersecurity, UX/UI Design, and many other related fields,
          or just want to drop by to chill and make some new friends, there's 
          something for everyone here at the CSI PHCET Developers’ Club.
        </p>
        <p>
        As a community driven towards the idea of “Technology For All”, 
        we invite everyone to  come be a part of our club and take steps 
        to a better learning experience equipped with all the latest trends
         while being a part of a close-knit community that will always be 
         there to guide you at every step. No matter your requirements and 
         interests, there will always be a place for you at the CSI Students 
         Developers’ Club.
        </p>

      </div>
    </section>
  );
};

const OpeningParagraph = ({ width }) => {
  if (width < 700) {
    return (
      <p className={'descriptionOpeningParagraph'}>
          The CSI PHCET Developers’ Club is a community that
          helps students interested in technology to learn and grow together.
      </p>
    );
  } else {
    return (
      // eslint-disable-next-line
      <p className={'descriptionOpeningParagraph'} role="text">
        The CSI PHCET Developers’ Club is a community that
        <br />
        helps students interested in technology to learn and grow together.
      </p>
    );
  }
};

const MissionContent = ({ width }) => {
  // The line doesn't evenly break until ~716px so we need to up it from 700px
  if (width < 720) {
    return (
      <p className={'missionContent'}>
         We aim to help and assist students interested in technology to meet other
         like-minded students and to help advance their careers.
      </p>
    );
  } else {
    return (
      // eslint-disable-next-line
      <p className={'missionContent'} role="text">
        <br/>We aim to help and assist students interested in technology <br/>
        to meet other like-minded students and to help advance their
        careers.
      </p>
    );
  }
};

export default DescriptionSection;
