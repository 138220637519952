import React from 'react'

function PcAssemble() {
    return (
        <>
            {/* <p>
                "𝙈𝙖𝙠𝙚 𝙚𝙫𝙚𝙧𝙮𝙩𝙝𝙞𝙣𝙜 𝙖𝙨 𝙨𝙞𝙢𝙥𝙡𝙚 𝙖𝙨 𝙥𝙤𝙨𝙨𝙞𝙗𝙡𝙚, 𝙗𝙪𝙩 𝙣𝙤𝙩 𝙨𝙞𝙢𝙥𝙡𝙚𝙧.”
                — 𝘼𝙡𝙗𝙚𝙧𝙩 𝙀𝙞𝙣𝙨𝙩𝙚𝙞𝙣✨


                Have you faced problems with your PC?? If yes then how to solve them?? The best way to solve your PC problems is to know your PC...And if you have ever wondered how is this PC made, this event is just for you.💻

                <p>Gear up for our next🔥🔥
                    '𝗣𝗖 𝗔𝗦𝗦𝗘𝗠𝗕𝗟𝗬 𝗪𝗢𝗥𝗞𝗦𝗛𝗢𝗣 𝘄𝗶𝘁𝗵 𝗖𝗦𝗜 𝗣𝗛𝗖𝗘𝗧'🔥'</p>

                <p>💡𝐓𝐡𝐢𝐧𝐠𝐬 𝐭𝐨 𝐛𝐞 𝐜𝐨𝐯𝐞𝐫𝐞𝐝 𝐢𝐧 𝐭𝐡𝐞 𝐰𝐨𝐫𝐤𝐬𝐡𝐨𝐩::</p>

                <p>▪𝗚𝗲𝘁 𝘁𝗼 𝗸𝗻𝗼𝘄 𝗮𝗯𝗼𝘂𝘁 𝘃𝗮𝗿𝗶𝗼𝘂𝘀 𝗰𝗼𝗺𝗽𝘂𝘁𝗲𝗿 𝗵𝗮𝗿𝗱𝘄𝗮𝗿𝗲.</p>
                <p>▪️𝗛𝗼𝘄 𝘁𝗼 𝘀𝗲𝗹𝗲𝗰𝘁 𝗽𝗲𝗿𝗳𝗲𝗰𝘁 𝗰𝗼𝗺𝗽𝗼𝗻𝗲𝗻𝘁𝘀 𝗳𝗼𝗿 𝘆𝗼𝘂𝗿 𝗣𝗖.</p>
                <p> ▪️𝗕𝘂𝗶𝗹𝗱 𝘆𝗼𝘂𝗿 𝗼𝘄𝗻 𝗣𝗖.</p>


                <p>𝘚𝘰, 𝘸𝘩𝘢𝘵 𝘢𝘳𝘦 𝘺𝘰𝘶 𝘸𝘢𝘪𝘵𝘪𝘯𝘨 𝘧𝘰𝘳? 𝘕𝘦𝘸 𝘦𝘹𝘱𝘦𝘳𝘪𝘦𝘯𝘤𝘦𝘴 𝘢𝘸𝘢𝘪𝘵 𝘺𝘰𝘶 💫</p>

            </p> */}
            <p>
                CSI PHCET organized and orientation program ‘PC Assembly Workshop’ on 13th April 2022 (Wednesday).
            </p>
            <p>
                Our objective for organizing this event was to let the students get a hand on experience on
                PC Assembly and to remove all the confusion between the huge list of specs on computer
                hardware.
            </p>
            <p>
                This event was a expert session taken by <b>Mr. Ganesh Sasane</b>.
            </p>
            <p>
                This event started with a brief knowledge about what all components are present
                in a CPU and why are they used, the current latest technologies present in it,
                and more! For getting a hand on experience a CPU was disassembled to know how the
                components are connected within it.
            </p>
            <p>
                After making the students understand some of them were told to assemble a full PC.
            </p>
            <p>
                At the end they all obtained the knowledge about the specs and could make their own PC
                with all the information of the components.
            </p>
        </>
    )
}

export default PcAssemble;
