import firebase from "firebase/app";
import "firebase/auth";
// import {useHistory} from 'react-router-dom'

firebase.initializeApp({
  apiKey: "AIzaSyCo_BJv67-F8eU2M9CJlk_24pLK_uV25F4",
  authDomain: "csi-phcet-35b11.firebaseapp.com",
  databaseURL: "https://csi-phcet-35b11-default-rtdb.firebaseio.com",
  projectId: "csi-phcet-35b11",
  storageBucket: "csi-phcet-35b11.appspot.com",
  messagingSenderId: "660398073387",
  appId: "1:660398073387:web:677b95fa8204330e23ac6e",
  measurementId: "G-D3SR32C6F5"
});

export const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
  auth.signInWithPopup(googleProvider).then((res) => {
    // user object
    // console.log(res.user)
  }).catch((error) => {
    // console.log(error.message)
  })
}

export const logOut = () => {
  auth.signOut().then(()=> {
    // console.log('logged out')
    window.location="/"
  }).catch((error) => {
    // console.log(error.message)
  })
}