import React, {useState,useEffect} from 'react';
import './Header.css';
import logo from '../../../assets/logo.png';
import hamburgerMenuIcon from '../../../assets/icons/hamburger-menu.svg';
import CloseIcon from '../../../assets/icons/close.svg'
import { Link } from 'react-router-dom';
import firebase from 'firebase/app'
import {logOut} from "../../fblog"

const Header = () => {
   const currentUser  = firebase.auth().currentUser;
   const [isLogin, setisLogin] = useState(false)
   const [menuStatus, setmenuStatus] = useState(false);
  useEffect(() => {
    if(currentUser){
        setisLogin(true)
        // console.log("Current User : ",currentUser)
        // console.log("login : ",isLogin)
    }
    else{
      setisLogin(false)
      // console.log('null user')
      // console.log("login : ",isLogin)
    }
  },[])

   const openMenu = () => {
    setmenuStatus(!menuStatus);
    document.getElementById('')
  }
  const scrollTop = () => {
    if(window.scrollY>10){
      window.scrollTo(0,0);
    }
  }

  return (
    <header>
      <nav className={'navbarMain'}>
        <div className={'navbarMainItems'}>
          <div className={'navLogo'}>
            <Link to="/">
              <a onClick={scrollTop}>
                <img width="50px" height="50px" className="logo" src={logo} alt="Logo" />
                <p role="text">
                  CSI PHCET
                </p>
              </a>
            </Link>
          </div>
          <div className={`${menuStatus? "showbackshadow" : "hidebackshadow"}`}></div>
          <nav className={`navbarMainLinks  ${menuStatus ? "showsidebar" : "hidesidebar"}`}>
         
            <ul className="sidebar">
                    <li className="closeIcon" >
                      <div className={'sidebarLogo'}>
                        <Link onClick={openMenu} to="/">
                          <img src={logo} alt="Logo" />
                          <small>CSI PHCET</small>
                        </Link>
                      </div>
                      <img onClick={openMenu} src={CloseIcon} alt={'close_btn'} />
                    </li>
                    <li>
                      <Link onClick={openMenu} to="/about" className='MenuItem'><a>About</a></Link>
                    </li>
                    <li>
                      <Link onClick={openMenu} to="/events"className='MenuItem'><a>Events</a></Link>
                    </li>
                    <li>
                      <Link onClick={openMenu} to="/resources"className='MenuItem'><a>Resources</a></Link>
                    </li>
                    <li>
                      <Link onClick={openMenu} to="/contribute"className='MenuItem'><a>Contribute</a></Link>
                    </li>
                    {
                      (isLogin)?  
                        <> 
                              <li>
                                <Link onClick={openMenu} to="/dashboard"className='MenuItem'><a>Dashboard</a></Link>
                              </li>
                              <li>
                                <Link onClick={openMenu} to="/profile"className='MenuItem'><a>Profile</a></Link>
                              </li>
                              <li>
                                <Link onClick={openMenu} to="/careers"className='MenuItem'><a>Career</a></Link>
                              </li>
                              <li>
                                <Link onClick={logOut} to="/"><a className='MenuItem'>Logout</a></Link>
                              </li>
                        </>
                        :
                        <li>
                              <Link onClick={openMenu} to="/login"className='MenuItem'><a>Login</a></Link>
                        </li>
                    }
                    {
                      (isLogin)?
                       <></>:
                       <div className="sidebar_bottom">
                       <li className={'joinButton'}>
                         <a target="blank" href="https://forms.gle/ks29fTFS8sH9BpRE9">Join the Club</a>
                       </li>
                     </div>
                    }
                   
            </ul>
            
          </nav>
          <button
            className={`navbarHamburgerMenuButton ${menuStatus ? "hidemenuicon" : "showmenuicon"}`}
            onClick={openMenu}
          >
            <img src={hamburgerMenuIcon} alt="Open menu" />
          </button>
        </div>
      </nav>
    </header>
  );
}

export default Header;
