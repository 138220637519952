import React from 'react';

function CPWorkshop() {
    return (
        <>
            <p>CSI PHCET organized a two-day peer-to-peer event on Competitive Programming On the 10th and 11th of April 2021. The idea was to share knowledge and information among peers to cultivate a comfortable and interactive learning environment. This could be seen as a part of our goal “For the students, By the students.”</p>
            <p>The event saw a large number of attendees and a great energy throughout the two days. The speakers for the event were Het Patel and Kishan Patel from TE Computer Department. They did a great job. The two-day event of Competitive Programming came out to be a success with everyone learning new skills on new topics and a better familiarity with the speakers as part of our peer-to-peer event planing part.</p>
            <p>Firstly, basic concepts required were explained along with question and answer sessions to form an interactive environment. Further on, more information about Competitive Programming was provided. As we followed a hands-on approach, one of the segments taught were practical problems were in real time problems were taught to the attendees and they were encouraged to solve it themselves too.</p>
            <p>Many at-home assignment questions were provided for the attendees to practice. At the end, a query session took place and also, attendees were provided with a certificate for the respective event.</p>
        </>
    )
}

export default CPWorkshop;
