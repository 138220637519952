import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import '../../css/Member.css';

const PastMembers = (props) => {
  return (
    <Accordion alwaysOpen>
      <div className={'contributeRole'}>
        <Accordion.Toggle
          className={'contributeRoleToggle'}
          eventKey="0"
        >
          <h2>{props.title}</h2>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <div>
            {props.children}
          </div>
        </Accordion.Collapse> 
      </div>
    </Accordion>
  );
};
export default PastMembers;
