import React from 'react';
import './Ending.css';

const Ending = ({ width }) => {
  return (
    <section className={"ending"}>
      <div className={"endingContainer"}>
        <EndingText width={width} />
        <a target="blank" href="https://forms.gle/ks29fTFS8sH9BpRE9">Join the Club</a>
      </div>
    </section>
  );
};

const EndingText = ({ width }) => {
  if (width < 700) {
    return (
      <h2>
        Unlock your full potential at the CSI PHCET Developer Club.
      </h2>
    );
  } else {
    return (
      // eslint-disable-next-line
      <h2 role="text">
        Unlock your full potential at the
        <br />
        CSI PHCET Developer Club.
      </h2>
    );
  }
};

export default Ending;
