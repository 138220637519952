import React from "react";
import "./App.css";
import $ from "jquery";
// import Load from "./Load.js" // please do not remove this comment........
import "./Loading.css";

export default function Logo() {
  // $(function(){
  //   setInterval(function(){
  $("body").addClass("active");
  //   }, 550);
  // });

  return (
    <>
      <div className="container ">
        <div
          className="fancy-bulb"
          style={{
            margin: "auto",
            position: "relative",
            marginTop: "25%",
            width: "200px",
            height: "200px",
          }}
        > 
        </div>
      </div>
      {/* <Load/>  */}
    </>
  );
}
