import React from 'react';

const InternInsight = () => {
    return (
        <>
            <p>✨𝗚𝗿𝗲𝗲𝘁𝗶𝗻𝗴𝘀✨</p>
            <p>We wanted to provide an interesting possibility that might make your holiday break not only productive but also extremely rewarding!
            </p>

            <p>🌟 Internship Insights Webinar: Unlock Your Potential!</p>


            <p>🗣𝗦𝗽𝗲𝗮𝗸𝗲𝗿: Mr.Vinod B</p>

            <p>
                Agenda:
                <ul>
                    <li>1.🚀 Importance of Internships</li>
                    <li>2.🌐 Navigating the Online Internship Landscape</li>
                    <li>3.🛠️ Building Skills for Your Future</li>
                    <li>4.🤝 Networking Tips for Success</li>
                </ul>
            </p>


            <p>
                📅 𝗗𝗮𝘁𝗲: 21 December 2023 <br />
                🕑 𝗧𝗶𝗺𝗲:  3-4:30 pm <br />
                📍 𝗩𝗲𝗻𝘂𝗲:  Google meet  <br />
            </p>

            <p>
                💥 First 100 members only!! </p>
            <p>
                🎯𝗙𝗼𝗿 𝗮𝗻𝘆 𝗳𝘂𝗿𝘁𝗵𝗲𝗿 𝗾𝘂𝗲𝗿𝗶𝗲𝘀, 𝗱𝗼 𝗻𝗼𝘁 𝗵𝗲𝘀𝗶𝘁𝗮𝘁𝗲 𝘁𝗼 𝗿𝗲𝗮𝗰𝗵 𝗼𝘂𝘁 𝘁𝗼 𝗼𝘂𝗿 𝗰𝗼𝗼𝗿𝗱𝗶𝗻𝗮𝘁𝗲𝘀:
                <br />
                📞Tanisha Paras: +91 8591121224<br />
                📞Tanay Jadhav : +91 8879013023 <br />
            </p>

            <p>Don't miss out on this chance to gain valuable insights and kickstart your professional journey!

                Let's make this holiday season one of growth and learning!

                See you there!✨</p>

            <p>
                Warm regards,
                <br />
                𝗧𝗲𝗮𝗺 𝗖𝗦𝗜 𝗣𝗛𝗖𝗘𝗧
            </p>
        </>

    );
};

export default InternInsight;
