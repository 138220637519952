import React,{useState,useEffect} from 'react'
import './css/ProfileComponent.css'
import { Button } from '@material-ui/core'
import axios from 'axios'
import firebase from 'firebase/app'

function ProfileComponent() {
  const currentUser = firebase.auth().currentUser
    const [Salutation, setSalutation] = useState("")
    const [Name, setName] = useState("")
    const [DOB, setDOB] = useState("")
    const [Email, setEmail] = useState("")
    const [Mobile, setMobile] = useState("")
    const [Degree, setDegree] = useState("")
    const [DegreeYear, setDegreeYear] = useState("")
    const [NewOrRenewal, setNewOrRenewal] = useState("")
    const [MembershipPeriod, setMembershipPeriod] = useState("")
    const [AmtPaid, setAmtPaid] = useState("")
    


    useEffect(() => {
        const data = JSON.stringify({
            Email : currentUser.email
          }) 
        axios.post("https://thxakskwod.execute-api.ap-south-1.amazonaws.com/default/myFirstFunction", data)
        .then(function (response) {
                if(response.data.length){
                    // console.log("response from aws:",response.data);
                    setSalutation(response.data[0]['Salutation'])
                    setName(response.data[0]['StudentName'])
                    let dateofBirth = response.data[0]['Dob']
                    dateofBirth = dateofBirth.replaceAll("/","-")
                    setDOB(dateofBirth)
                    setEmail(response.data[0]['Email'])
                    setMobile(response.data[0]['MobileNo'])
                    setDegree(response.data[0]['Degree'])
                    setDegreeYear(response.data[0]['DegreeYear'])
                    setNewOrRenewal(response.data[0]['NeworRenewal'])
                    setMembershipPeriod(response.data[0]['MembershipPeriod'])
                    setAmtPaid(response.data[0]['AmountPaid'])
                }
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
        // console.log("response data : ",error.response.data);
        // console.log("response status :  ",error.response.status);
        // console.log("response headers : ",error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
        // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
        }
      // console.log(error.config);

      // console.log(error);
      });     
    }, [])
    const updatedData = JSON.stringify({
      StudentName :  Name,
      Email : Email,
      Dob : DOB.replaceAll("-","/"),
      MobileNo : Mobile
    })
  const onSubmit = () => {
    axios.post("https://iwcpxngv1h.execute-api.ap-south-1.amazonaws.com/default/updateMember",updatedData)
    .then(function (response) {
          // console.log("Response : ",response)
            if(response.status === 200){
              alert('Your Profile has been updated!')
            }
  })
  .catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
    // console.log("response data : ",error.response.data);
    // console.log("response status :  ",error.response.status);
    // console.log("response headers : ",error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    // console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
    // console.log('Error', error.message);
    }
  // console.log(error.config);

  // console.log(error);
  });     
  } 

    // console log
    // console.log(Salutation)
    // console.log(Name)
    // console.log(DOB)
    // console.log(Email)
    // console.log(Mobile)
    // console.log(Degree)
    // console.log(DegreeYear)
    // console.log(NewOrRenewal)
    // console.log(MembershipPeriod)
    // console.log(AmtPaid)
    return (
        <div style={{marginLeft:'5px'}}>
            <h1 className='profileHeader'>Profile</h1>
            <div  className='profiledetails'>
                <div className='studentdetail'><label className='label'>Student Name</label>        <div style={{display:'flex'}}>
                                                                                                        {/* <select style={{marginRight:'10px'}} value={Salutation} onChange={ (e) => setSalutation(e.target.value) } style={{width:'55px'}} disabled>
                                                                                                            <option value="Mr.">Mr.</option>
                                                                                                            <option value="Mrs.">Mrs.</option>
                                                                                                        </select> */}
                                                                                                        <input name="name" value={Name} onChange={ (e) => setName(e.target.value)}/>
                                                                                                    </div>
                </div>
                {/*<div className='studentdetail'><label className='label'>DOB</label>                 <input type="date" value={DOB} onChange={ (e) =>setDOB(e.target.value)} /></div>*/}
                <div className='studentdetail'><label className='label'>Email</label>               <input type="email" value={Email} onChange={ (e) => setEmail(e.target.value)} disabled/></div>
                <div className='studentdetail'><label className='label'>Mobile No.</label>          <input type="tel" value={Mobile} onChange={ (e) => setMobile(e.target.value)} id="phone" name="phone" pattern="[0-9]{10}"/></div>
                <div className='studentdetail'><label className='label'>Degree</label>              <input name="degree" value={Degree} onChange={ (e) => setDegree(e.target.value)} disabled/></div>
                <div className='studentdetail'><label className='label'>Current Year</label>         <input value={DegreeYear} onChange={ (e) => setDegreeYear(e.target.value)} disabled/></div>
                <div className='studentdetail'><label className='label'>New/Renewal</label>         <input value={NewOrRenewal} onChange={ (e) => setNewOrRenewal(e.target.value)} disabled/></div>
                <div className='studentdetail'><label className='label'>Membership Period</label>   <input value={MembershipPeriod+" Year"} onChange={ (e) => setMembershipPeriod(e.target.value)} disabled/></div>
                <div className='studentdetail'><label className='label'>Amount Paid</label>   <input value={AmtPaid} onChange={ (e) => setAmtPaid(e.target.value)} disabled/></div>
                <div style={{display:'flex',justifyContent:'center'}}>
                    <Button
                        className="savebtn"
                        shape="round"
                        onClick={onSubmit}
                        variant="inherit" color="Primary"
                        >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ProfileComponent
