import React,{useEffect} from 'react';
import WelcomeSection from '../components/index/WelcomeSection/WelcomeSection';
import Ending from '../components/common/Ending/Ending';
import DescriptionSection from '../components/index/DescriptionSection/DescriptionSection';
import HighlightsSection from '../components/index/HighlightsSection/HighlightsSection';
import Header from '../components/common/Header/Header';
import Footer from './Footer';

function HomePage({width}) {    
    useEffect(() => {
        window.scrollTo({
          top: 0, 
        });
      }, []);
  return (
        <div className="home">
            <Header/>
            <WelcomeSection width={width} />
            <DescriptionSection width={width} />
            <HighlightsSection />
            <Ending width={width}/>
            <Footer/>
        </div>
    )
}

export default HomePage;
