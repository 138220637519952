import React from 'react'

const  PythonWorkshop = () => {
    return (
        <>
            <p>Computer Engineering Department has an integral CSI student chapter known as CSI PHCET, which aims to enhance and develop students core skills mostly by organizing different workshops, competitions and events. </p>
            <p>CSI PHCET organized a workshop on Python which was held on 6th and 7th March 2021.  The workshop was conducted by Prof. Suhas Lawand. </p>
            <p>On day 1 i.e., 6th March 2021 the points that were covered are –</p>
                <ul>
                    <li>Basics of Python -
                      How to initialize variables and their data types along with input/output functions
                      Why commenting is necessary and where to use comments</li>
                    <li>Control Structures-
                      Syntax and declaration of loops like if-else, while etc.
                      Use of operators like comparison, Boolean etc.</li>
                    <li>Data structures in Python-
                      The description of how to use strings and inbuilt string manipulation function
                      The description on how to use lists, inbuilt list manipulation function and list comprehension
                      Detailed explanation and examples on Tuples, Dictionary and sets</li>
                    <li>Functions-
                      Scope of variables
                      Function parameters, calling and return</li>
                    <li>Modules in Python-
                    Different modules like collections, random, time etc. were also covered
                    **above were implemented by solving various problems 
                    ** quiz test was also conducted at the end of the session</li>
                </ul>
            <p>On day 2 i.e., 7th March 2021 the topics covered are –</p>
                    <ul>
                        <li>Object-Oriented Concepts-
                      A detailed description on Classes, Objects and members
                      Use of constructors and their functions along with abstract classes
                      Topics like Inheritance, Polymorphism, Method overloading and overriding were also covered.</li>
                        <li>Exception handling in Python-
                    How to declare exceptions in Python and how they are declared differently in Python than in other languages.
                    Types of exception like User-defined exception and inbuilt exception</li>
                    </ul>
        </>
    )
}

export default PythonWorkshop;
