import React from 'react'

function FunFriday() {
    return (
        <>
            <p>
                CSI PHCET organized a fun event called Fun Friday on 11th March 2022.</p>
            <p>
                Our objective for organizing this event was to let the students know about
                the CSI club and how we conduct not only technical events but also fun events.</p>
            <p>
                The main Objective was to gather students and let them enjoy
                the various non technical and technical games and interact with other students as well.</p>
            <p>
                The event featured numerous fun games and activities, such as:
            </p>
            <ul>
                <li>
                    <b>Non-Technical</b>
                    <ul>
                        <li><b>Box Cricket League:</b>
                            <p>It is a 5 vs 5 team match of cricket.
                                The highest one to score runs in both teams wins the match and
                                wins the cash price.
                            </p>
                        </li>
                        <li><b>Wheel of Fortune:</b>
                            <p>Participants have to perform the tasks given in the wheel and if the pointer
                                ends up in the price section then they win the price.
                            </p>
                        </li>
                        <li><b>Dumb Charades:</b>
                            <p>It is a 2 vs 2 team game in which one participant of the team has
                                to wear headphones in which music is played and the other participant
                                has to guess the movie name given by the co-ordinators who ever guesses
                                the most number of movies wins the price.
                            </p>
                        </li>
                    </ul>
                </li>


                <li>
                    <b>Technical</b>
                    <ul>
                        <li><b>101 and out:</b>
                            <p>It is a 3 vs 2 team game in which the participants have to roll
                                the dice and add the numbers of the outcome of the dice and reach he
                                goal ie 101 the closes team to 101 wins the game.
                            </p>
                        </li>
                        <li><b>Guess the code:</b>
                            <p>In this game several snippets of code are given on screen participant has to
                                guess that in which language the code is written in.
                            </p>
                        </li>
                        <li><b>Binary Gird:</b>
                            <p>There is a 6x6 grid and at the end of it there are some numbers the participant has to
                                arrange 1 and 0 in such a way that the number in the last has to match its binary number.
                            </p>
                        </li>
                    </ul>
                </li>
            </ul>
            <p>
                The event started with a boom, several students registered for various games and enjoyed playing it.
            </p>
            <p>
                More than 100 students attended the fun event.
            </p>
            <p>
                Overall the event was a great Success and the students responded very well.
            </p>
        </>
    )
}

export default FunFriday 
