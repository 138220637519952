import React from 'react';
import '../../../css/Card.css';
import { LinkedIn, Twitter } from '@material-ui/icons';

function CardView({profileimage,profilename,profilerole,subprofilerole,sociallinkdin,socialtwitter}) {
    return (
        <div className='cardview'>
            <img 
            className="profile"
            src={profileimage} 
            alt="profileimage"
            />
            <h4>{profilename}</h4>
            <span className='role'>  {profilerole}  </span>
            <span className='role'>  {subprofilerole}  </span>
            <p>
                <a href={socialtwitter} target="_blank">
                    <Twitter fontSize="large" className="socialprofile twitter"/>
                </a>
                <a href={sociallinkdin} target="_blank">
                    <LinkedIn fontSize="large" className="socialprofile linkedin"/>
                </a>
            </p>
        </div>
    )
}
export default CardView;
