import React from 'react'
import ProfileComponent from '../components/Profile/ProfileComponent'
import Header from '../components/common/Header/Header'
import Footer from './Footer'

function Profile() {
    return (
        <>
            <Header/>
            <ProfileComponent/>
            <Footer/>
        </>
    )
}

export default Profile
