import React from 'react';

const EHackinhWorkshop = () => {
    return (
        <>
            <p>Computer Engineering Department has an integral CSI student chapter known as CSI PHCET, which aims to enhance and develop students core skills mostly by organizing different workshops, competitions and events.</p>
            <p>CSI PHCET Student Chapter has organized an online webinar on “Ethical Hacking” on 30th November 2020. This workshop was conducted by Mr Roshan Thakur, Cyber Security Analyst Engineer with 2+ years of experience in Cyber IT Security field with IFF MH Cyber and Cyber Crime Helpline. He also worked with Maharashtra Cyber Police for AMBIS Government Project association with IFF as an intern.</p>
            <p>As a Chief Guest, we have invited Manish Advani, an award-winning Storyteller, Story Writer, TEDx / Global Speaker and a Business Leader with over 20 years of experience spanning across diverse functionalities like Learning and Development, Purpose based Branding, Sales and Business Development and many more. He has worked as a Head of Marketing & Communications at Schindler - Mumbai, Head of Change Management at Mahindra SSG - Mumbai, Senior Analyst at Wunderman - Toronto, Entrepreneur - Mumbai.Currently, he is working as a Brand and Story Consultant, Mumbai</p>
            <p>This webinar aimed to dictate different aspects of Ethical Hacking, introducing different tools and technologies required and guiding students on how to become a professional hacker.</p>
            <p>The webinar covered some basic and Advance topic based on “Ethical Hacking” are as follow :</p>
            <ul>
                <li>Computer Security and Hacking Anticipation</li>
                <li>Reverse Engineering</li>
                <li>Software Concept</li>
                <li>BackDoor Attack</li>
                <li>Vulnerabilities in website and Website Hacking</li>
                <li>How to Become a Professional Hacker</li>
            </ul>
        </>
    )
}
export default EHackinhWorkshop;
