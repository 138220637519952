import React, { useState } from 'react';
import Typewriter from 'typewriter-effect';
import './WelcomeSection.css';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';

const WelcomeSection = (props) => {
  return (
    <section className={'welcome'}>
      <div className={'welcomeBackgroundContainer'}>
        <div className={'welcomeContainer'}>
          <div className={'welcomeContent'}>
            <Slogan width={props.width} />
            <p className={'welcomeDescription'}>
              Learn something new at an event, form a team to build a project
              with, or find out more about the field! The CSI PHCET Developer Club welcomes you, regardless of your programming
              experience or department.
            </p>
            <div className={'welcomeActionButtons'}>
                  <a target="blank" href="https://forms.gle/ks29fTFS8sH9BpRE9" className={'joinButton'}>Join&nbsp;the&nbsp;Club</a>
              <Link to='/about' className={'learnMore'}>
                <a>Learn More</a>
              </Link>
            </div>
          </div>

          <div className={'welcomeImage'}>
                    <div className='player-wrapper'>
                        <ReactPlayer
                          playing={true}
                          volume={1}
                          light={true}
                          className='react-player'
                          url='https://www.youtube.com/watch?v=IrJxOFvjqyo'
                          width='100%'
                          height='100%'
                        />
                  </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Slogan = (props) => {
    return (
      // eslint-disable-next-line
      <span className={'slogan sloganBeginning'} role="text">
        <h1 className={'sloganBeginning'}>
          A community driven
         <p className="animatedtext">
           by&nbsp;
           <span className={'typewriterPlaceholder'}><TypewriterWelcome/></span>
         </p> 
        </h1>
      </span>
    );

};

const TypewriterWelcome = () => {
  const [displayPlaceholder, setDisplayPlaceholder] = useState(true);
  const strings = [
    'inspiration.',
    'collaboration.',
    'diversity.',
    'passion.',
    'creativity.',
    'learning.',
  ];
  const typewriterInit = (typewriter) => {
    if (displayPlaceholder) {
      setDisplayPlaceholder(false);
    }
    const typeString = (string, pauseTime) => {
      typewriter.typeString(string).pauseFor(pauseTime).deleteAll(30);
    };
    for (const string of strings) {
      typeString(string, 2500);
    }
    typewriter.start();
  };
  return (
    <>
      {displayPlaceholder && (
        <span className={'typewriterPlaceholder'}>inspiration.</span>
      )}
      <Typewriter
        options={{
          autoStart: true,
          loop: true,
          delay: 30,
          deleteSpeed: 30,
        }}
        onInit={(typewriter) => typewriterInit(typewriter)}
      />
    </>
  );
};

export default WelcomeSection;
