import React from 'react';
import { Link } from 'react-router-dom';
import './Highlight.css';

const Highlight = (props) => {
  return (
    <div
      className={'highlight'}
      style={{ backgroundColor: props.backgroundColor }}
    >
      <div className={`${'highlightContainer'} ${props.containerClass}`}>
        <div className={'highlightContent'}>
          <h2>{props.title}</h2>
          <p>{props.description}</p>
            {(props.linkText==='Join The Community')?(
                    <a href={props.linkUrl}>{props.linkText} →</a>
                ):
                (
                  <Link to={props.linkUrl}>
                    <a>{props.linkText} →</a>
                  </Link>
                )
            }
        </div>
        <img
          className={'highlightImage'}
          src={props.image}
          alt={props.imageAlt}
        />
      </div>
    </div>
  );
};

export default Highlight;
