import React from 'react'
import CardView from '../about/Cards/CardFaculty'
import '../../css/Team.css'

import EkataMam from '../../assets/pages/about/team-members/Ekata-1.jpg'
import jointchairman from '../../assets/pages/about/team-members/joint-chairman.jpg'

function FacultyDetail() {
    return (
        <div className="team">
            <br/>
            <div>
                <CardView  sociallinkdin={"https://www.linkedin.com/in/ekta-pashine-55492549/"} profileimage={EkataMam} profilename={"Ekta Ukey"} profilerole={"Student Branch Coordinator"} subprofilerole={"And Nominee Member"}/>
                <CardView socialtwitter={'https://mobile.twitter.com/Rajat__Rai'} sociallinkdin={'https://www.linkedin.com/in/rajat-rai-96298222b'} profileimage={jointchairman} profilename={"Rajat Rai"} profilerole={"Jt. Chairman"} />
            </div>
            <br/><br/><br/>
        </div>
    )
}
export default FacultyDetail
