import React from 'react'

function iot3() {
  return (
      <>
          <p>
              <strong>CSI PHCET</strong> organized an Tech Thrive Chapter 3 ‘GUARDIANS OF THE GALAXY- THE IDEATHON’ on 3rd october 2022 (monday).Our objective for organizing this event was to let the students explore their knowledge and represent their unique ideas of all engineering branches.The program was held in the conclave-2 in which about 80 approx. students were present from different branches of engineering. 
          </p>

          <p>
              The event started by an introduction speech and felicitation of the judges for the program. It was continued by the rules for the competition. The Principal delivered a speech and encouraged the students to take part in such events and what an idea can bring change in their life. Then 9 teams competed against each other and represented their ideas and told what's unique about it.It was an interesting activity.The judges choose the best 2 ideas as the 1st and 2nd place winners.They were awarded by certificates and medals. Also CSI PHCET announced  the recruitment process result  for the core team and new members were welcomed to the team with the batches of CSI PHCET CORE TEAM.  All participants were very enthusiastic.An amazing day came to an end, and all the students returned with new ideas and great joy
          </p>
      </>
  )
}

export default iot3;