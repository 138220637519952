import React from 'react'

function Tools() {
  return (
    <div style={{ textAlign: 'justify' }}>
      <h2 id="collections">Collections</h2>
      <hr />
      <ul>
        <li><a href="https://education.github.com/pack"><strong>GitHub Student Developer Pack</strong></a><br />A collection of premium tools and services that are free for students.</li>
        <li><a href="https://free-for.dev"><strong>free-for.dev</strong></a><br />A large collection of tools and services that have free tiers for everyone to use.</li>
        <li><a href="https://webgems.io"><strong>webgems.io</strong></a><br />webgems is a goto place for devs and designers to find new resources and more.</li>
        <li><a href="https://www.bookmarks.design"><strong>bookmarks.design</strong></a><br />A collection of resources for designers.</li>
      </ul>
      <h2 id="utilities">Utilities</h2>
      <hr />
      <ul>
        <li><a href="https://squoosh.app"><strong>Squoosh</strong></a><br />Squoosh is an image compression web app that allows you to dive into the advanced options provided by various image compressors.</li>
        <li><a href="https://www.remove.bg"><strong>remove.bg</strong></a><br />A tool that removes the background from images.</li>
        <li><a href="https://getwaves.io"><strong>Get Waves</strong></a><br />A free SVG wave generator to make unique SVG waves for your next web design. Choose a curve, adjust complexity, randomize!</li>
      </ul>
      <h2 id="design">Design</h2>
      <hr />
      <h3 id="tools">Tools</h3>
      <ul>
        <li><a href="https://www.figma.com"><strong>Figma</strong></a><br />Figma is a vector graphics editor and prototyping tool used for designing application mockups.</li>
      </ul>
      <h3 id="inspiration">Inspiration</h3>
      <ul>
        <li><a href="https://dribbble.com"><strong>Dribbble</strong></a><br />A platform for designers to share their creations.</li>
      </ul>
      <h3 id="icons">Icons</h3>
      <ul>
        <li><a href="https://fontawesome.com"><strong>Font Awesome</strong></a><br />A popular font and icon toolkit.</li>
        <li><a href="http://simpleicons.org"><strong>Simple Icons</strong></a><br />Over 1000 Free SVG icons for popular brands.</li>
      </ul>

    </div>
  )
}

export default Tools;
