import React,{useState} from "react"; 
import "../css/Login.css";
import { auth } from "../components/fblog";
import firebase from "firebase/app";
import "firebase/auth";
import Google from '../assets/icons/google.png'
import axios from 'axios'
import Footer from './Footer'
import Modal from 'react-modal'
import { Close } from "@material-ui/icons";

const googleProvider = new firebase.auth.GoogleAuthProvider();
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius:'20px',
    transform: 'translate(-50%, -50%)',
    width:'80vw',
    maxWidth:'500px',
    height:'auto',
  },
};
Modal.setAppElement('#root');
 function Login(){
   const currentUser =  firebase.auth().currentUser
  const signInWithGoogle = () => {
    auth.signInWithPopup(googleProvider).then((res) => {
      // user object
      // if(res.user.email==currentUser)
      // console.log(res.user.email)
      // console.log(res.user.displayName)
      // console.log("currentUser : ",currentUser)
      // console.log(res.user.photoURL)
      const data = JSON.stringify({
        Email : res.user.email
      })      
      axios.post("https://thxakskwod.execute-api.ap-south-1.amazonaws.com/default/myFirstFunction", data)
      .then(function (response) {
        // console.log("response from aws:",response.data);

        if(response.data.length){
          // console.log("logged in") 
          window.location = "/dashboard"
        }else{
          // console.log('You are not yet CSI Member..Join Now!')
          setIsOpen(true)
          auth.signOut().then(()=> {
          }).catch((error) => {
            // console.log(error.message)
          })
        }
        
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log("response data : ",error.response.data);
          // console.log("response status :  ",error.response.status);
          // console.log("response headers : ",error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
        }
        // console.log(error.config);

        // console.log(error);
      });     
      
    }).catch((error) => {
      // console.log(error.message)
    })
  }
  const OpenForm = () => {
    setIsOpen(false)
    window.location = "https://forms.gle/ks29fTFS8sH9BpRE9"
  }
  // Modal
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#000';
  }
  function closeModal() {
    setIsOpen(false);
  } 
  return (
    <>
      <div className="login">
          <div className="loginWindow">
                  <h2>Member Log In</h2>
                  <div className="login-buttons">
                      <button className="login-provider-button" onClick={signInWithGoogle}>
                          <img src={Google} alt="google icon" /> 
                          <span> Sign In with Google</span>
                    </button>
                  </div>
                  <div className="endsection" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <div><b>Not a member yet?</b><a target="_blank" href="https://forms.gle/ks29fTFS8sH9BpRE9">{"   "}Join Now</a></div>
                        <br/>
                        <div className="reportIssue"><small>mail us at <a href="mailto:csi.phcet@mes.ac.in">csi.phcet@mes.ac.in</a>, if you face any issue while signing in</small></div>
                  </div>
              <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
          <button onClick={closeModal} 
                  className="Modal_Close_Btn"
                  varient="contained"
                ><Close className="Modal_Close_Icon"/>
          </button>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}
              className="Modal_Header">You are not yet a CSI PHCET Member!</h2>
          <a  className="joinNow" target="blank"
                    style={{textDecoration:'none',display:'flex',justifyContent:'center'}}
                    onClick={OpenForm}>
            Join Now
          </a>
      </Modal>
    </div>
          </div>
      </div>
    <Footer/>
    </>
  );

}
export default  Login;

