import React, { useEffect } from 'react';
import HostEvent from '../components/contribute/HostEvent/HostEvent';
import Volunteer from '../components/contribute/Volunteer/Volunteer';
import Header from '../components/common/Header/Header';
import { Link as ScrollLink } from 'react-scroll';
import Footer from './Footer';


function Contribute() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  return (
    <>
      <Header />
      <div className="contribute" style={{ textAlign: 'justify'}}>
        <div
          className={`container text contribute`} 
        >
          <section>
            <h1 className={'centerElement'}>Contribute to the Club</h1>
            <p>
              The Computer Society of India, Phcet is a community-first,
              student-led organization made possible by a community of
              passionate students and innovative minds. Students from
              any department or background are welcome to learn and grow in
              our diverse and inclusive community.
            </p>

            <p>
              In order to further achieve our goal of being a community-first
              club, we're looking for some of our very own club members that
              share our vision to help shape the future of the CSI Club!
            </p>

            <p>
              If you're interested in contributing to the club, here are a few ways you can do so:
            </p>
            <ul className={'list'}>
              <li>
                <ScrollDownPage to="volunteer">
                  Volunteer for the club
                </ScrollDownPage>
              </li>

              <li>
                <ScrollDownPage to="host-event">
                  Host and lead an event at the club
                </ScrollDownPage>
              </li>
            </ul>
          </section>

          {/* Sections of ways to contribute */}
          <Volunteer />

          <HostEvent />
        </div>
      </div>
      <Footer />
    </>
  )
}

// A preset react-scroll link for this page.
const ScrollDownPage = (props) => (
  <ScrollLink
    href={`#${props.to}`}
    to={props.to}
    duration={300}
    offset={-60}
    smooth
  >
    {props.children}
  </ScrollLink>
);
export default Contribute;
