import React from 'react'

function MockAptitude1 () {
    return (
        <>
            <p>
            CSI PHCET conducted the Mock Aptitude Test I on 18th July 2021. It was intended to measure a student's intelligence and enable them to know what they should work on and improve. In addition, we tried to give clarification on the kinds of questions asked.
            </p>
            <p>
            Students in the 3rd and 4th years of PHCET showed tremendous interest in the event.
            </p>
            <p>
            Thus, the test contained approximately 20 questions, which included a wide variety of questions such as numerical reasoning, verbal reasoning, logical reasoning, and diagrammatic reasoning etc. Participants had 45 minutes to complete the test.
            </p>
            <p>
            The test's top scorer was Bhavin Patel from BE A, who scored 95 marks out of 100. 
            With everyone getting a self-assessment after the Mock Aptitude Test I, the event turned out to be a huge success. 
            </p>
        </>
    )
}

export default MockAptitude1 
