import React from 'react';
import Highlight from './Highlight';
import './HighlightsSection.css';
import './Highlight.css';
import learnImage from '../../../assets/pages/home/highlights/Learn.jpg';
import collaborateImage from '../../../assets/pages/home/highlights/Collaborate.jpg';
import networkImage from '../../../assets/pages/home/highlights/Network.jpg';

const descriptions = [
  // Learn
  `Whether you’ve never seen a line of code before or you’ve written 
  full-stack applications that scale, we’ve got you covered. With tech
   talks, workshops, competitions, challenges, and more, there’s plenty
    of ways to curate new skills and level up your career or even just 
    your resume! Our exciting events, ranging from beginner to advanced
     levels, will get you up and running with your first website or on 
     how to deploy and auto-scale your application on the cloud.`,

  // Collaborate
  `Teamwork makes the dream work! Find team members to work on a project 
  with, collaborate on activities, form your winning hackathon team, or 
  practice mock interviewing and whiteboarding with others to score your
   dream job.`,

  // Network
  `The CSI PHCET Developer Club is built upon the foundations of diversity 
  and availability.  Connect with people from all walks of life, available
   at all times and grow your network with some of the brightest people 
   in the industry while also enhancing your team skills with peer-to-peer 
   learning programmes.`,
];

const HighlightsSection = () => {
  return (
    <section className={'highlights'}>
      {/* Learn */}
      <Highlight
        containerClass={'highlightLearn'}
        title="Learn"
        description={descriptions[0]}
        linkUrl="/events"
        linkText="Browse Events"
        image={learnImage}
        imageAlt="Learn"
        backgroundColor="#FFF9EE"
      />

      {/* Collaborate */}
      <Highlight
        containerClass={'highlightCollaborate'}
        title="Collaborate"
        description={descriptions[1]}
        linkUrl="/about"
        linkText="See What We Do"
        image={collaborateImage}
        imageAlt="Collaborate"
        backgroundColor="#EEFFF0"
      />

      {/* Network */}
      <Highlight
        containerClass={'highlightNetwork'}
        title="Network"
        description={descriptions[2]}
        linkUrl="https://forms.gle/ks29fTFS8sH9BpRE9"
        linkText="Join The Community"
        image={networkImage}
        imageAlt="Network"
        backgroundColor="#EEF7FF"
      />
    </section>
  );
};

export default HighlightsSection;
