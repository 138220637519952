import React from 'react'
import '../css/Career.css'
import Header from '../components/common/Header/Header'
import CareerComponent from '../components/CareerSupport/CareerComponent'
import Footer from './Footer'

function Career() {
    return (
        <>
            <Header/>
            <h1 className='careerHeader'>Careers</h1>
            <CareerComponent/>
            <div style={{marginBottom:'180px'}}/>
            <Footer/>
        </>
    )
}

export default Career
