import React, { useEffect } from 'react';
import TopSection from '../components/about/TopSection/TopSection';
import WhoWeAreSection from '../components/about/WhoWeAreSection/WhoWeAreSection';
import Ending from '../components/common/Ending/Ending';
import Header from '../components/common/Header/Header';
import Faculty from './Faculty';
import Footer from './Footer';
import TeamDetail from '../components/TeamDetail/TeamDetail';
import FacultyDetail from '../components/FacultyDetail/FacultyDetail';


import '../css/About.css';

function About() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div className="about">
      <Header />
      <TopSection />
      <WhoWeAreSection />
      <h1 className="meetteam">Faculty Co-ordinators</h1>
      <Faculty />

      {/* NBA COMMITTEE WORK */}
      <h1 className="meetteam">Application Developed By</h1>
      <div className="member" style={{ textAlign: 'justify' }}>
        <div
          className={`member`}
        >
          <FacultyDetail />
        </div>
      </div>
      {/* NBA COMMITTEE WORK */}
      
      <h1 className="meetteam">Meet The Team</h1>
      <div className="member" style={{ textAlign: 'justify' }}>
        <div
          className={`member`}
        >
          <TeamDetail />
        </div>
      </div>
      <Ending />
      <Footer />
    </div>
  )
}

export default About;
