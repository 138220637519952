import React from 'react'

function iot() {
    return (
        <>
            <p>
                <strong>CSI PHCET</strong> organized an orientation program <strong>‘Tech Thrive: Internet Of Things’</strong> on 23rd August 2022 (Tuesday).
            </p>

            <p>
                Our objective for organizing this event was to give the students a brief information about Internet Of Things (IOT) domain knowledge and to get an opportunity to have a one on one interaction with the industrial expert.
            </p>
            <p>
                This event was an expert session taken by <strong>Mr. Prashant Saini</strong> who is currently working as Mobile App Developer at Navitus Education.
            </p>

            <p>
                This event started with a theoretical knowledge about Internet Of Things with its application in various fields like medical, education, industries, etc. how IOT is making it’s progress with the growing technology, examples of currently IOT working mechanisms and systems, various components used in IOT and how the components have evolved with time making them more effective and budget friendly.
            </p>

            <p>
                Physical components were made available for students to get a practical knowledge about them and at the end our guest speaker demonstrated some of the IoT projects that he developed and how we can upgrade those projects to make them work on a larger scale.
            </p>

        </>
    )
}

export default iot;
