import React from 'react'

function iot2() {
    return (
        <>
            <p>
                <strong>CSI PHCET</strong>organized a hands-on workshop on ‘Tech Thrive: Internet Of Things (Chapter 2)’ on 21st September 2022 (Wednesday).
            </p>
            <p>
                Our objective for organizing this event was to give the students a brief information about Internet Of Things (IOT) domain knowledge and to get an opportunity to have a one on one interaction with our speaker
            </p>
            <p>
                This event was an expert session taken by Mr. Shubham Thakur who is the faculty of Department of electronics and telecommunication at Pillai HOC.
            </p>
            <p>
                This event started with a theoretical knowledge about Internet Of Things with its application. Then our speaker explained his applications on IOT and explained various components used in making an IOT project.
            </p>
            <p>
                After our speaker explained about the projects they got to see the development of the projects live followed by which the students were divided into three groups. These groups were provided with the raw components needed to build a project from scratch by creating a competitive environment between the groups, whichever group made a working project on Distance detector using an ultrasonic sensor  won the competition.
            </p>
            <p>
                Our team members also made an announcement  about our upcoming events and also gave a brief context on our recruitment process whose results will be announced in our next event.
            </p>
        </>
    )
}

export default iot2;
