import React from 'react'

function GitHubWorkshop() {
    return (
        <>
            <p>CSI PHCET conducted a peer-to-peer workshop
                on  Git and Github on 31st July 2021 in order
                to share knowledge and information among
                students about this software and platform.
                This can be seen as a part of our goal
                “For the students, by the students”.</p>
            <p>
                Event was conducted by Rishabh Mehta, a
                third year computer engineering student.
                Students showed tremendous interest in the event.
                For sure it helped them a lot with learning about
                these open source software and services in detail.
                As it will result in helping them with their
                projects be it individual or group projects.
            </p>
            <p>The session was held for 2 hours. The 1st half
                of the session consisted of a brief  explanation
                on Git and the 2nd half consisted of a session
                on github. Both theory and practical segments
                were covered effectively.
            </p>
            <p>The topics that were included in the session:</p>
            <b>Git</b>
            <ul>
                <li>Version Control explanation.</li>
                <li>Basic of gits and  setup</li>
                <li>Git commands</li>
                <li>Git concepts in detail.</li>
            </ul>
            <b>Github</b>
            <ul>
                <li>Introduction to github</li>
                <li>Steps to contribute to open source projects.</li>
                <li>Briefly explanation of how this platform can be used effectively.</li>
            </ul>
        </>
    )
}

export default GitHubWorkshop
