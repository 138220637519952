import React, { useEffect } from 'react';
import '../css/Resources.css';
import Alert from 'react-bootstrap/Alert';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from '../components/common/Header/Header';
import Learn from '../assets/pages/resources/learn';
import Tools from '../assets/pages/resources/tools';
import Footer from './Footer';

function Resources() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    }, []);
    return (
        <>
            <Header />
            <div
                className="container text resources"
            >
                <h1 className="centerElement" >Resources</h1>
                <Learn />
                <br />
                <h1 className="centerElement">Useful Tools</h1>
                <Tools />
                <Alert variant="primary" className="contribute">
                    <Alert.Heading>Contribute to this list!</Alert.Heading>
                    <p>
                        Have any resources you want to share with us? Post them in our
                        Discord community under the <code>#resources</code> channel
                    </p>
                </Alert>
            </div>
            <Footer />
        </>
    )
}

export default Resources;
