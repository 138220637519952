import React from 'react';
import './WhoWeAreSection.css';
import '../../../css/About.css';

const WhoWeAreSection = () => {
  return (
    <section className={"whoWeAre"}>
      <h2 className={"heading"}>Who We Are</h2>

      <div className={"paragraphGroup"}>
        <p>
          The CSI PHCET Student Club is PHCET’s student-led,
          community-first computer science and technology
          community where students interested in technology
          and latest industry trends come together to learn
          and grow in a engaging, open and welcoming environment.
        </p>

        <p>
          Together as a community, we share our knowledge and
          offer peer-to-peer learning options to build
          projects and attend sessions and events on in-demand
          skills, discuss the latest trends and technologies,
          and more! We aim to build a community where you
          would feel confident on expressing your unique
          ideas and put forward your questions without any
          apprehensions.
        </p>

        <p>
          The most innovative software engineers, data scientists,
           and UX/UI designers all started from somewhere! We 
           welcome members of all skill levels and fields, ranging 
           from complete beginner developers to the most seasoned 
           designers. We would love to have your presence in our
            club at any and all levels. We promise you that your
             presence will add something to this club and help 
             other members in growing!
        </p>

        <p>
          We aim to make every member feel comfortable in what
           they do and help each other to grow from the roots. 
           Whether you need help with your projects, want some 
           advice about your career, or simply want to learn more
            about the field, our friendly community members can 
            help point you in the right direction as we go on to 
            learn more about this field together.
        </p>

        <p>
          No matter what your current programming experience is or whether you’re planning on continuing in this industry or not, there will always be a place for you in our CSI PHCET Student Club.
          We hope to see you soon as one of us as we go on to learn together.
        </p>

      </div>
    </section>
  );
};

export default WhoWeAreSection;
