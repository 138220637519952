import React from 'react'

function CodeCrush() {
    return (
       <>
            <p>
                Computer Engineering Department has an integral CSI
                 student chapter known as CSI PHCET, which aims to 
                 enhance and develop students core skills mostly by 
                 organizing different workshops, competitions and events.
            </p>
            <p>
                CSI PHCET organized a Code Crush Competition which was held on 2nd May 2021.  
            </p>
            <p>
                🏆Presenting to you the 𝘄𝗶𝗻𝗻𝗲𝗿𝘀 𝗼𝗳 𝗖𝗼𝗱𝗲 𝗖𝗿𝘂𝘀𝗵:
            </p>
            <p>
                🥇 𝗩𝗶𝘀𝗵𝗮𝗹 𝗥𝗮𝗷𝗮𝗸 -TE Comps
                (Won 𝗥𝘀.𝟯𝟬𝟬/-cash prize)
            </p>
            <p>
                🥈 𝗧𝗲𝗷𝗮𝘀 𝗠𝗮𝗴𝗮𝗱𝗲- SE IT
                (Won 𝗥𝘀. 𝟮𝟬𝟬/-cash prize)
            </p>
       </>
    )
}

export default CodeCrush;
