import React from 'react'
import CardView from '../components/about/Cards/CardTeam'
import '../css/Team.css';
import chairman from '../assets/pages/about/past-team-members-22/chairman.jpg'
import jointchairman from '../assets/pages/about/past-team-members-22/joint-chairman.jpg'
import headbusiness from '../assets/pages/about/past-team-members-22/head-business.jpg'
import businesscore1 from '../assets/pages/about/past-team-members-22/businesscore1.jpeg'
import businesscore2 from '../assets/pages/about/past-team-members-22/businesscore2.png'
import businesscore3 from '../assets/pages/about/past-team-members-22/businesscore3.jpeg'
import headsocialmedia from '../assets/pages/about/past-team-members-22/head-social-media.jpg'
import jointheadtresurer from '../assets/pages/about/past-team-members-22/joint-head-tresurer.jpg'
import jointheadtechnical from '../assets/pages/about/past-team-members-22/joint-head-technical.jpeg'
import secretary from '../assets/pages/about/past-team-members-22/secretary.png'
import jointheadsecretary from '../assets/pages/about/past-team-members-22/joint-head-secretary.jpg'
import publicity from '../assets/pages/about/past-team-members-22/publicity.jpeg'
import publicity1 from '../assets/pages/about/past-team-members-22/publicity1.jpeg'
import event from '../assets/pages/about/past-team-members-22/event.jpg'
import eventcore3 from '../assets/pages/about/past-team-members-22/eventcore3.jpg'
import webdev from '../assets/pages/about/past-team-members-22/webdev.jpg'
import webdev1 from '../assets/pages/about/past-team-members-22/webdev1.jpg'
import webdev2 from '../assets/pages/about/past-team-members-22/webdev2.jpg'

function PastTeam() {
    return (
        <div className="team">
            <br />
            <div>
                <CardView socialtwitter={'https://twitter.com/hetpatel54'} sociallinkdin={'https://www.linkedin.com/in/het-patel-1496581aa/'} profileimage={chairman} profilename={"Het Patel"} profilerole={"Chairman"} />
                <CardView socialtwitter={'https://twitter.com/maxslimb'} sociallinkdin={'https://www.linkedin.com/in/kishan-patel-ab62811aa/'} profileimage={jointchairman} profilename={"Kishan Patel"} profilerole={"Jt. Chairman"} />
                <CardView socialtwitter={''} sociallinkdin={'https://www.linkedin.com/in/abhishek-rajpurohit-340595192/'} profileimage={headbusiness} profilename={"Abhishek R."} profilerole={"Business Management Head"} />
                <CardView socialtwitter={'https://x.com/riiz_zy?s=20'} sociallinkdin={'https://www.linkedin.com/in/rishikesh-tekavade-b865ab1a4/'} profileimage={secretary} profilename={"Rishikesh Tekavade"} profilerole={"Secretary"} />
            </div>
            <hr />
            <div>
                <CardView socialtwitter={'https://twitter.com/SimranSurve2'} sociallinkdin={'https://www.linkedin.com/in/simransurve14/'} profileimage={jointheadsecretary} profilename={"Simran Surve"} profilerole={"Jt. Secretary"} />
                <CardView socialtwitter={'https://twitter.com/Sakshi__25'} sociallinkdin={'https://www.linkedin.com/in/sakshi-chauhan-6b6a651a9/'} profileimage={event} profilename={"Sakshi Chauhan"} profilerole={"Jt. Event Head"} />
                <CardView socialtwitter={''} sociallinkdin={''} profileimage={headsocialmedia} profilename={"Prathmesh P."} profilerole={"Social Media "} subprofilerole={"And Publicity Head"} />
                <CardView socialtwitter={'https://twitter.com/Vaibhav_192'} sociallinkdin={'https://www.linkedin.com/in/vaibhav-kadam-18b2171b8/'} profileimage={webdev} profilename={"Vaibhav Kadam"} profilerole={"Web Developer"} />
            </div>
            <hr />
            <div>
                <CardView socialtwitter={'https://twitter.com/HarshSh62173900'} sociallinkdin={'https://www.linkedin.com/in/harsh-shah95/'} profileimage={jointheadtresurer} profilename={"Harsh Shah"} profilerole={"Jt. Treasurer"} />
                <CardView socialtwitter={'https://twitter.com/shrupatil81?s=08'} sociallinkdin={'https://www.linkedin.com/in/shrushti-patil-88544b1b6'} profileimage={businesscore1} profilename={"Shrushti Patil"} profilerole={"Business Core Member"} />
                <CardView socialtwitter={''} sociallinkdin={'https://linkedin.com/in/prajwalmali'} profileimage={businesscore2} profilename={"Prajwal Mali"} profilerole={"Business Core Member"} />
                <CardView socialtwitter={'https://twitter.com/Devang79379124?s=08'} sociallinkdin={'https://www.linkedin.com/in/devang-parate-b3602a218/'} profileimage={businesscore3} profilename={"Devang Parate"} profilerole={"Business Core Member"} />
            </div>
            <hr />
            <div>
                <CardView socialtwitter={'https://twitter.com/SanskritiKhars1'} sociallinkdin={'https://www.linkedin.com/in/sanskriti-kharsamble-037935182/'} profileimage={publicity1} profilename={"Sanskriti K."} profilerole={"Editor in Chief"} />
                <CardView socialtwitter={'https://twitter.com/nitishnshetty?t=EvkqNHY_A_h2q2MHXgWK6Q&s=09'} sociallinkdin={'https://www.linkedin.com/in/nitish-shetty-b6533a15b'} profileimage={eventcore3} profilename={"Nitish S."} profilerole={"Event Core Member"} />
                <CardView socialtwitter={'https://twitter.com/ShettyPaavana'} sociallinkdin={'https://www.linkedin.com/in/paavana-shetty-8a5b7a20b/'} profileimage={publicity} profilename={"Paavana Shetty"} profilerole={"Jt. Publicity Head"} />
                <CardView socialtwitter={'https://twitter.com/Parth41173665'} sociallinkdin={'https://www.linkedin.com/in/parth-pitrubhakta-4b211a1a1/'} profileimage={jointheadtechnical} profilename={"Parth P."} profilerole={"Jt. Technical Head"} />
            </div>
            <hr />
            <div>
                <CardView socialtwitter={'https://twitter.com/SamiAhmed541'} sociallinkdin={'https://www.linkedin.com/in/sami-ahmed-5602301b2/'} profileimage={webdev2} profilename={"Sami Ahmed"} profilerole={"Jt. Web Developer"} />
                <CardView socialtwitter={'https://twitter.com/SakshiNikum?s=08'} sociallinkdin={'https://www.linkedin.com/in/sakshi-nikum-049a26208'} profileimage={webdev1} profilename={"Sakshi Nikum"} profilerole={"Jt. Web Developer"} />
            </div>
        </div>
    )

}

export default PastTeam;
