import React from 'react'
import CardView from '../components/about/Cards/CardTeam'
import '../css/Team.css';

import chairman from '../assets/pages/about/past-team-members-23/chairman.jpg'
import jointchairman from '../assets/pages/about/past-team-members-23/joint-chairman.jpg'

import headsecretary from '../assets/pages/about/past-team-members-23/head-secretary.jpg'
import jointsecretary from '../assets/pages/about/past-team-members-23/joint-secretary.jpg'

import headtreasurer from '../assets/pages/about/past-team-members-23/treasurer.jpg'
import jointtreasurer from '../assets/pages/about/past-team-members-23/joint-treasurer.jpg'

import eventhead from '../assets/pages/about/past-team-members-23/event-head.jpg'
import eventteam1 from '../assets/pages/about/past-team-members-23/event-team-member-1.jpg'
import eventteam2 from '../assets/pages/about/past-team-members-23/event-team-member-2.jpg'
import eventteam3 from '../assets/pages/about/past-team-members-23/event-team-member-3.jpg'

import publicityteam1 from '../assets/pages/about/past-team-members-23/publicity-team-member-1.jpg'
import publicityteam2 from '../assets/pages/about/past-team-members-23/publicity-team-member-2.jpg'
import publicityteam3 from '../assets/pages/about/past-team-members-23/publicity-team-member-3.jpg'
// import publicityteam4 from '../assets/pages/about/past-team-members-23/publicity-team-member-4.jpg'
import publicityteam5 from '../assets/pages/about/past-team-members-23/publicity-team-member-5.jpg'
import publicityteam6 from '../assets/pages/about/past-team-members-23/publicity-team-member-6.jpg'

import socialmediamanager from '../assets/pages/about/past-team-members-23/social-media-manager.jpg'

import technicalhead from '../assets/pages/about/past-team-members-23/technical-head.jpg'
import technicalteam1 from '../assets/pages/about/past-team-members-23/technical-team-member-1.jpg'
import technicalteam2 from '../assets/pages/about/past-team-members-23/technical-team-member-2.jpg'

import bdhead from '../assets/pages/about/past-team-members-23/bd-head.jpg'
import jtbdhead from '../assets/pages/about/past-team-members-23/joint-bd-head.jpg'
import bdexec1 from '../assets/pages/about/past-team-members-23/bd-exec-1.jpg'
import bdexec2 from '../assets/pages/about/past-team-members-23/bd-exec-2.jpg'
import bdexec3 from '../assets/pages/about/past-team-members-23/bd-exec-3.jpg'
import bdexec4 from '../assets/pages/about/past-team-members-23/bd-exec-4.jpg'
import bdexec5 from '../assets/pages/about/past-team-members-23/bd-exec-5.jpg'

import editorhead from '../assets/pages/about/past-team-members-23/editor-head.jpg'
import editor1 from '../assets/pages/about/past-team-members-23/editor-1.jpg'
import editor2 from '../assets/pages/about/past-team-members-23/editor-2.jpg'

import devhead from '../assets/pages/about/past-team-members-23/developer-head.jpg'
import webdev from '../assets/pages/about/past-team-members-23/web-developer.jpg'
import jtwebdev from '../assets/pages/about/past-team-members-23/joint-web-developer.jpg'
import appdev from '../assets/pages/about/past-team-members-23/android-developer.jpg'


function Team() {
    return (
        <div className="team">
            <br />
            {/* Chairman */}
            <div>
                <CardView socialtwitter={'https://twitter.com/SanskritiKhars1'} sociallinkdin={'https://www.linkedin.com/in/sanskriti-kharsamble-037935182/'} profileimage={chairman} profilename={"Sanskriti K."} profilerole={"Chairman"} />
                <CardView socialtwitter={''} sociallinkdin={''} profileimage={jointchairman} profilename={"Prathmesh P."} profilerole={"Jt. Chairman"} />
            </div>
            <hr />

            {/* Secretory */}
            <div>
                <CardView socialtwitter={'https://twitter.com/ShettyPaavana'} sociallinkdin={'https://www.linkedin.com/in/paavana-shetty-8a5b7a20b/'} profileimage={headsecretary} profilename={"Paavana Shetty"} profilerole={"Secretary"} />
                <CardView socialtwitter={'https://twitter.com/yashsmahajan82'} sociallinkdin={'https://www.linkedin.com/in/yash-mahajan-90b0b5219'} profileimage={jointsecretary} profilename={"Yash Mahajan"} profilerole={"Jt. Secretary"} />
            </div>
            <hr />

            {/* Treasurer */}
            <div>
                <CardView socialtwitter={'https://twitter.com/Sahil1523?t=_AVkr3P5SDdXI52UVVTe3g&s=08'} sociallinkdin={'https://www.linkedin.com/in/sahil-gaikwad-b3a980223'} profileimage={headtreasurer} profilename={"Sahil Gaikwad"} profilerole={"Treasurer"} />
                <CardView socialtwitter={'https://twitter.com/jtanay1009'} sociallinkdin={'https://www.linkedin.com/in/tanay-jadhav-1a4a89252/'} profileimage={jointtreasurer} profilename={"Tanay Jadhav"} profilerole={"Jt. Treasurer"} />
            </div>
            <hr />

            {/* Event */}
            <div>
                <CardView socialtwitter={'https://twitter.com/Sakshi__25'} sociallinkdin={'https://www.linkedin.com/in/sakshi-chauhan-6b6a651a9/'} profileimage={eventhead} profilename={"Sakshi Chauhan"} profilerole={"Event Head"} />
                <CardView socialtwitter={'https://twitter.com/beg_ramsha?t=1R1iiwwVpapgiwU8_5Px4w&s=08'} sociallinkdin={'https://www.linkedin.com/in/ramsha-beg-2002/'} profileimage={eventteam3} profilename={"Ramsha Beg"} profilerole={"Event Team Member"} />
                <CardView socialtwitter={'https://twitter.com/NiharPasi?t=9nvFv5QldjWG_jdnxMT6YA&s=08'} sociallinkdin={'https://www.linkedin.com/in/nihar-pasi-b28443232'} profileimage={eventteam1} profilename={"Nihar Pasi"} profilerole={"Event Team Member"} />
                <CardView socialtwitter={'https://twitter.com/BhaktiBhoir0404?t=C0R_3XfJrn55UUPOcO6iSg&s=08'} sociallinkdin={'https://www.linkedin.com/in/bhakti-bhoir-786054232'} profileimage={eventteam2} profilename={"Bhakti Bhoir"} profilerole={"Event Team Member"} />

            </div>
            <hr />

            {/* Publicity */}
            <div>
                <CardView socialtwitter={''} sociallinkdin={''} profileimage={publicityteam1} profilename={"Rutuja Kore"} profilerole={"Publicity Team Member"} />
                <CardView socialtwitter={'https://twitter.com/YashShi78753687?t=_F0weH0kU-sQlu2KPIAC4A&s=08'} sociallinkdin={'https://www.linkedin.com/in/yash-shinde-079156232'} profileimage={publicityteam2} profilename={"Yash Shinde"} profilerole={"Publicity Team Member"} />
                <CardView socialtwitter={''} sociallinkdin={''} profileimage={publicityteam3} profilename={"Tanvi Patil"} profilerole={"Publicity Team Member"} />
            </div>
            <div>
            <CardView socialtwitter={'https://twitter.com/HrishiBhilare'} sociallinkdin={'www.linkedin.com/in/hrishi-bhilare-8b5140252'} profileimage={publicityteam6} profilename={"Rushikesh Bhilare"} profilerole={"Publicity Team Member"} />
                <CardView socialtwitter={'https://twitter.com/ShagunJha2?t=bpfxZqIxHVLDM5E8UwhHjg&s=09'} sociallinkdin={'https://www.linkedin.com/in/shagun-jha-6279a4252'} profileimage={publicityteam5} profilename={"Shagun Jha"} profilerole={"Publicity Team Member"} />
            </div>
            <hr />

            {/* Social Media*/}
            <div>
                <CardView socialtwitter={'https://twitter.com/PREM30116'} sociallinkdin={'https://www.linkedin.com/in/prem-singh-07a46322b'} profileimage={socialmediamanager} profilename={"Prem Singh"} profilerole={"Social Media Head"} />
            </div>
            <hr />

            {/* Technical */}
            <div>
                <CardView socialtwitter={''} sociallinkdin={''} profileimage={technicalhead} profilename={"Abhishek Raha"} profilerole={"Technical Head"} />
                <CardView socialtwitter={'https://twitter.com/Pgoverkar'} sociallinkdin={'https://www.linkedin.com/in/pratham-goverkar-09256622b/'} profileimage={technicalteam1} profilename={"Pratham Goverkar"} profilerole={"Technical Team Member"} />
                <CardView socialtwitter={'https://twitter.com/AbhishekAmbre_?t=8OH85_owlMtNQK9NNpQQHQ&s=09'} sociallinkdin={'https://www.linkedin.com/in/abhishek-ambre-53905b253'} profileimage={technicalteam2} profilename={"Abhishek Ambre"} profilerole={"Technical Team Member"} />
            </div>
            <hr />

            {/* Business Development*/}
            <div>
                <CardView socialtwitter={'https://twitter.com/Devang79379124?s=08'} sociallinkdin={'https://www.linkedin.com/in/devang-parate-b3602a218/'} profileimage={bdhead} profilename={"Devang Parate"} profilerole={"Business Development Head"} />
                <CardView socialtwitter={'https://twitter.com/shrupatil81?s=08'} sociallinkdin={'https://www.linkedin.com/in/shrushti-patil-88544b1b6'} profileimage={jtbdhead} profilename={"Shrushti Patil"} profilerole={"Joint Business Development Head"} />
                <CardView socialtwitter={''} sociallinkdin={''} profileimage={bdexec1} profilename={"Salil Liman"} profilerole={"Business Development Executive"} />
                <CardView socialtwitter={''} sociallinkdin={'https://linkedin.com/in/prajwalmali'} profileimage={bdexec2} profilename={"Prajwal Mali"} profilerole={"Business Development Executive"} />
            </div>
            <div>
                <CardView socialtwitter={'https://twitter.com/uknowRishabh?t=AzSCwLvialvoFk1zYfemAQ&s=09'} sociallinkdin={'https://www.linkedin.com/in/rishabh-kumar-a018901ba'} profileimage={bdexec3} profilename={"Rishabh Kumar"} profilerole={"Business Development Head"} />
                <CardView socialtwitter={'https://twitter.com/utkarshaC17'} sociallinkdin={'https://www.linkedin.com/in/utkarsha-chaudhari-723943232/'} profileimage={bdexec4} profilename={"Utkarsha Chaudhari"} profilerole={"Business Development Executive"} />
                <CardView socialtwitter={'https://twitter.com/NandiniBodkhe?t=xpTE9FTk8uKC_oFW3iM0vg&s=09'} sociallinkdin={'https://www.linkedin.com/in/nandini-bodkhe-068a86252'} profileimage={bdexec5} profilename={"Nandini Bodkhe"} profilerole={"Business Development Executive"} />
            </div>
            <hr />

            {/* Editor */}
            <div>
                <CardView socialtwitter={'https://twitter.com/yaashhpatil?t=eEL5PoXCH0nCYd62UWPbRg&s=09'} sociallinkdin={'https://www.linkedin.com/in/yash-patil-844388221'} profileimage={editorhead} profilename={"Yash Patil"} profilerole={"Editor in Chief"} />
                <CardView socialtwitter={'https://twitter.com/Himansu_gh?t=Or0uWUoI_nLMMRQRpBCsPA&s=08'} sociallinkdin={'https://www.linkedin.com/in/himansu-sekhar-sahoo-2a0051232'} profileimage={editor1} profilename={"Himansu Sahoo"} profilerole={"Editor"} />
                <CardView socialtwitter={'https://twitter.com/Shivam_Modhave'} sociallinkdin={'https://www.linkedin.com/in/shivam-modhave-142b31220'} profileimage={editor2} profilename={"Shivam Modhave"} profilerole={"Editor"} />
            </div>
            <hr />

            {/* Developer Team */}
            <div>
                <CardView socialtwitter={'https://twitter.com/SamiAhmed541'} sociallinkdin={'https://www.linkedin.com/in/sami-ahmed-5602301b2/'} profileimage={devhead} profilename={"Sami Ahmed"} profilerole={"Developer Team Head"} />
                <CardView socialtwitter={''} sociallinkdin={''} profileimage={appdev} profilename={"Karan Savaliya"} profilerole={"Android Developer"} />
                <CardView socialtwitter={'https://mobile.twitter.com/Rajat__Rai'} sociallinkdin={'https://www.linkedin.com/in/rajat-rai-96298222b'} profileimage={webdev} profilename={"Rajat Rai"} profilerole={"Web Developer"} />
                <CardView socialtwitter={'https://twitter.com/prathameshdk02'} sociallinkdin={'https://www.linkedin.com/in/prathamesh-kadve-9948ba232'} profileimage={jtwebdev} profilename={"Prathamesh Kadve"} profilerole={"Jt. Web Developer"} />
            </div>
        </div>
    )

}

export default Team;
