import React from 'react'

function ReactJS() {
    return (
        <>
            <p>
            CSI PHCET organized a workshop on Web Development using React Js which was held on 21st August  2021.  The workshop was conducted by Rohan Shah who is a software developer with expertise in React,React native,NodeJs,UI designing and Dev Ops. The number of attendees was 82. The event garnered tremendous interest among students. There is no doubt that it helped them learn a lot about Web development.
            </p>
            <p>
           The topics that were covered are –

            </p>
           <ul>
            <li>React App Setup -</li>
            <li>Understanding Components and Modularity-</li>
            <li>React JS basics.-</li>
            <li>Life cycle methods and their usage.-</li>
            <li>React Router -</li>
            <li>Virtual DOM explained.</li>
            <li>ES6 and its functions</li>
            <li>Promises and async functions</li>
            <li>State management.</li>
            <li>Code debugging and documentation.</li>
           </ul>
        </>
    )
}

export default ReactJS;
