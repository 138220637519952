import React from "react";
import { Element } from "react-scroll";
import Role from "./Role";
import "../../../css/Contribute.css";

const Volunteer = () => (
  <Element name="volunteer">
    <section className={"volunteer"} id="volunteer">
      <h2 className={"heading"}>Volunteer for the Club</h2>
      <p>
        As a Club we have plenty of duties that require a variety of skill
        sets,so even if you aren't interested in programming, you might still be
        able to help us out! Please take a look at our open roles below to see
        which roles would fit you best. Thank you for your interest in
        volunteering for us!
      </p>
      <h3 className={"openRolesHeading"}>Roles:</h3>
      <Secretary />
      <Event />
      <Publicity />
      <Technical />
      <Buisness />
      <WebDeveloper />
      <Editor />
      <SocialMediaManager />
      {/* <Treasure/> */}
      {/*<Eventhead/>*/}
      {/* <Eventteam/>*/}
    </section>
  </Element>
);

const Editor = () => (
  <Role title="Editor Team Member">
    <p>
      As an Editor, you will design and prototype user interfaces and
      interactions for the club including the club's website (the one you're
      currently on right now!)
    </p>

    <p>Requirements of the position are:</p>
    <ul>
      <li>Good knowledge of Photoshop and Canva for Graphic Art.</li>

      <li>
        Must be aware of technicalities of Premiere pro and After Effects for
        video designing.
      </li>
    </ul>

    <p>You will be able to:</p>
    <ul>
      <li>
        Watch as your design comes to life and publicize on various social media
        and the CSI Club platforms.
      </li>

      <li>
        Design Graphic Art and videos that will benefit everyone in the
        community.
      </li>

      <li>
        Gain access to an exclusive Discord channel where you can network and
        collaborate with other volunteers.
      </li>
      <li>Be the first to hear about new developments in the club.</li>
    </ul>
  </Role>
);

const WebDeveloper = () => (
  <Role title="Developer Team Member">
    <p>
      As a developer team contributor you will be working on Designing the
      website and updating about the upcoming events.
    </p>
    <ul>
      <li>
        You will get an access to our official CSI PHCET website which you are
        viewing now.
      </li>
    </ul>

    <p>Requirements of the position are:</p>
    <ul>
      <li>Good knowledge of Web Development, HTML5, CSS3 and JavaScript.</li>
      <li>
        Must be aware of technicalities of ReactJS, Material-UI, and Android
        Studio.
      </li>
    </ul>

    <p>You will be able to:</p>
    <ul>
      <li>Design the user experience and interface.</li>
      <li>
        Introduce your updates in it and everyone will be able to access your
        creation.
      </li>
      <li>
        Work from Designing the website to updating about the upcoming events,
        it will help you to gain experience in hosting a website which is in
        use.
      </li>
      <li>
        Work with the team for upcoming and existing projects of the community.
      </li>
      <li>Be the first to hear about new developments in the club</li>
    </ul>
  </Role>
);
// As a social media manager you are person within an organization who is trusted with monitoring,
//  executing, filtering and measuring the social media presence
//  of our club CSI PHCET in public.

const SocialMediaManager = () => (
  <Role title="Social Media Team Member">
    <p>
      As a social media team member, you will be a medium between the members
      and our club. You will be entrusted with monitoring, executing, filtering
      and measuring the social media presence of our club CSI PHCET in public.
    </p>
    <p> Requirements of the position are: </p>
    <ul>
      <li>Good photography skills</li>
      <li>Content Planning</li>
      <li>Follow Trends in Social Media.</li>
      <li>Flexibility to execute the assigned tasks.</li>
    </ul>

    <p>You will be able to:</p>
    <ul>
      <li>
        Collaborate and Engage with the other Social Media pages of our
        Partners/Associates.
      </li>
      <li>
        You can improve your soft skills while being the student representative
        of CSI PHCET.
      </li>
      <li>Come up with innovative ideas for social media.</li>
      <li>
        Gain access to an exclusive Discord channel where you can network and
        collaborate with other volunteers.
      </li>
      <li>Be the first to hear about new developments in the club</li>
    </ul>
  </Role>
);
const Event = () => (
  <Role title="Event Team Member">
    <p>
      As an Event Team Member, you will be tasked with planning and executing an
      event as soon as it is confirmed. A workflow will be provided for you.
      Besides brainstorming ideas, you will be responsible for organizing
      workshops within the assigned field. You should also host/attend workshops
      and make sure they go smoothly, as well as follow-up activities after the
      event.
    </p>
    <br />
    {/* <p>Requirements For The Position:</p>
    <ul>
      <li>Problem solving ability</li>
      <li>Good in making Presentations</li>
      <li>Previous experience of working in events.</li>
      <li>Versatile and flexible with tasks assigned.</li>
      <li>Good to have: Familiar with Discord</li>
    </ul> */}
    <p>You will be able to:</p>
    <ul>
      <li>
        Develop your teamwork skills since you would be working with different
        minds.
      </li>
      <li>
        {" "}
        Get Opportunities to network with your juniors, seniors as well as
        college faculties.
      </li>
      <li>
        Contributing to the success of a club will not only give you
        transferable skills, but increase your self-confidence.
      </li>
      <li>Lastly, the experience will make your CV more interesting:)</li>
    </ul>
  </Role>
);
const Buisness = () => (
  <Role title="Business Development Executive ">
    <p>
      As a Business development core member, you will be having following
      responsibilities:
    </p>
    <ul>
      <li>Market research</li>
      <li>Raising and partnership</li>
      <li>Scheduling and hosting meetings with clients.</li>
      <li>Onboarding clients.</li>
    </ul>
    <p>Requirements For The Position:</p>
    <ul>
      <li>Good communication skill</li>
      <li>Good hold over English</li>
      <li>Researching ability</li>
      <li>Should be eager to learn</li>
      <li>Business enthusiast</li>
      <li>Confident</li>
      <li>Good to have: Past experience in business development</li>
    </ul>
    <p>You will be able to:</p>
    <ul>
      <li>Build your corporate network.</li>
      <li>Learn corporate communication.</li>
      <li>Gain confidence.</li>
      <li>Enhance your soft skills.</li>
      <li>
        Develop your confidence, teamwork, and leadership skills while working
        with other core committee teams & members.
      </li>
      <li>
        Gain access to an exclusive Discord channel where you can network and
        collaborate with other volunteers
      </li>
      <li>Be the first to hear about new developments in the club</li>
    </ul>
  </Role>
);
const Secretary = () => (
  <Role title="Joint Secretary">
    <p>
      As a Joint Secretary you help the Secretary in managing the team and
      enhancing smooth functioning of the club. You make a bridge between higher
      authorities and the team. You manage various events and related
      procedures.
    </p>

    <p>You will be able to:</p>
    <ul>
      <li> Handle the team members and lead the team.</li>
      <li>
        Arrange meetings, keep their records and manage administrative work.
      </li>
      <li>Look into problems within the team and solve them wisely.</li>
      <li>Play a major role in events, partnerships and other activities.</li>
      <li>
        Gain access to an exclusive Discord channel where you can network and
        collaborate with other volunteers and be the first to hear about new
        developments in the club.
      </li>
    </ul>
  </Role>
);
const Treasure = () => (
  <Role title="Joint Treasurer">
    <p>
      As a Joint Treasurer you help the Treasurer in managing the finances of
      the club. You manage the spreadsheets and data included with CSI PHCET.
      You will be an important knot in dealing with sponsorships and
      partnerships.
    </p>

    <p>You will be able to:</p>
    <ul>
      <li> Manage budget and make budget plans for CSI PHCET.</li>
      <li>Handle the financial side of bringing memberships to the club.</li>
      <li>
        You can improve your soft skills while being the student representative
        of CSI PHCET.
      </li>
      <li>Come up with ideas for using the budget wisely.</li>
      <li>
        Gain access to an exclusive Discord channel where you can network and
        collaborate with other volunteers.
      </li>
      <li>Be the first to hear about new developments in the club</li>
    </ul>
  </Role>
);
const Eventhead = () => (
  <Role title="Joint Event head">
    <p>
      As a Joint Event Head, you are responsible for coming up with new ideas
      for the events, along with planning and executing them. You need to keep
      track of our event database, as well as interact with our various teams
      for all the general needs of our daily work.Record participants in these
      workshops and challenges and send completion emails, badges, etc whenever
      relevant.
    </p>

    <p>You will be able to:</p>
    <ul>
      <li> Lead with a quality of leadership.</li>
      <li>
        Play an important role in the events and workshops held by CSI PHCET.
      </li>
      <li>Enhance your soft skills and management skills.</li>
      <li>Be the first to hear about new developments in the club</li>
    </ul>
  </Role>
);
const Eventteam = () => (
  <Role title="Event Team Member">
    <p>
      As an Event Team Member, you will be tasked with planning and executing an
      event as soon as it is confirmed. A workflow will be provided for you.
      Besides brainstorming ideas, you will be responsible for organizing
      workshops within the assigned field. You should also host/attend workshops
      and make sure they go smoothly, as well as follow-up activities after the
      event.
    </p>

    <p>You will be able to:</p>
    <ul>
      <li>
        Develop your teamwork skills since you would be working with different
        minds.
      </li>
      <li>
        Get Opportunities to network with your juniors, seniors as well as
        college faculties.
      </li>
      <li>
        Contributing to the success of a club will not only give you
        transferable skills, but increase your self-confidence.
      </li>
      <li>Lastly, the experience will make your CV more interesting:) </li>
    </ul>
  </Role>
);
const Technical = () => (
  <Role title="Technical Team Member">
    <p>
      As a technical team member you will have the following responsibilities:{" "}
    </p>
    <ul>
      <li>Operate technical aspects of hosting online/offline events.</li>
      <li>
        Gain knowledge about the particular event beforehand, so that you will
        be able to solve others' doubts in the event.{" "}
      </li>
      <li> Basic knowledge about discord.</li>
      <li>Good presentation skills.</li>
    </ul>
    <p>You will be able to:</p>
    <ul>
      <li>Build technical expertise, which will help you in future.</li>
      <li>Be the first to hear about new developments in the club.</li>
      <li> Build your technical skill set.</li>
      <li>
        {" "}
        Gain access to an exclusive Discord channel where you can network and
        collaborate with other volunteers.
      </li>
      <li>Play a major role in events, partnerships and other activities.</li>
    </ul>
  </Role>
);
const Publicity = () => (
  <Role title="Publicity Team Member">
    <p>
      As a publicity team member, you help in generating positive publicity for
      our club. You help in keeping the students informed about the activities
      of CSI PHCET. You need to represent the club and help in encouraging
      students to join our club.
    </p>
    <p>Requirements of the position are:</p>
    <ul>
      <li>Good content writing skills</li>
      <li>Stage daring and good communication</li>
      <li>Networking skills</li>
      <li>Flexibility with the given task.</li>
    </ul>

    <p>You will be able to:</p>
    <ul>
      <li>
        {" "}
        Collaborate with content creator to help in publicizing about upcoming
        events.
      </li>
      <li>
        You can improve your soft skills while being the student representative
        of CSI PHCET.
      </li>
      <li>
        Come up with innovative ideas for publicity of events and membership
      </li>
      <li>
        Gain access to an exclusive Discord channel where you can network and
        collaborate with other volunteers.
      </li>
      <li>Be the first to hear about new developments in the club</li>
      <li>Chance to become your department's representative in CSI.</li>
    </ul>
  </Role>
);
export default Volunteer;
