import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import '../../../css/Contribute.css';

const Role = (props) => {
  return (
    <Accordion>
      <div className={'contributeRole'}>
        <Accordion.Toggle
          className={'contributeRoleToggle'}
          eventKey="0"
        >
          <h2>{props.title}</h2>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <div>
            {props.children}
            <a
              className={'contributeRoleApply'}
              href="https://docs.google.com/forms/d/e/1FAIpQLScjzXOVy2sx2-g0Vcihynve2lz393UiTSEPA77ZYsLa0FtB3A/viewform?usp=sf_link"
              target="_blank"
            >
              Apply for this Role
            </a>
          </div>
        </Accordion.Collapse> 
      </div>
    </Accordion>
  );
};

export default Role;
